import { Col, Form, FormInstance, Row } from "antd";

import { URGENCIES } from "../../../../constants/maintenance-requests";
import { IMaintenanceRequestForm } from "../../../../types/maintenance-requests";
import CommonSelect from "../../../inputs/Form/Inputs/CommomSelect";
import CommonCheckbox from "../../../inputs/Form/Inputs/CommonCheckbox";
import CommonTextArea from "../../../inputs/Form/Inputs/CommonTextArea";

const MaintenanceRequestForm = ({
    form,
    initialValues,
    onFinish,
}: {
    form: FormInstance;
    initialValues: IMaintenanceRequestForm;
    onFinish: (_values: IMaintenanceRequestForm) => void;
}) => {
    return <Form
        form={form}
        layout={"vertical"}
        initialValues={initialValues}
        onFinish={values => onFinish(values)}
    >
        <Row gutter={[32, 32]}>
            <Col span={24}>
                <div>
                    <CommonSelect
                        label={"Urgency"}
                        name={"urgency"}
                        options={URGENCIES}
                    />
                </div>

                <div>
                    <CommonTextArea
                        required
                        rows={4}
                        label={"Description"}
                        name={"description"}
                        placeholder={"Input description..."}
                    />
                </div>

                <div>
                    <CommonCheckbox
                        label={"Presentness"}
                        name={"presentness"}
                    />
                </div>

                <div>
                    <CommonCheckbox
                        label={"Completed"}
                        name={"completed"}
                    />
                </div>
            </Col>
        </Row>
    </Form>;
};

export default MaintenanceRequestForm;
