import { useCallback, useMemo, useState } from "react";

import useSocketNotifications from "./useSocketNotifications";
import { PAGE_SIZES } from "../../constants/pagination";
import { SOCKETS_EVENTS } from "../../constants/sockets";
import { useGetNotificationsQuery, useSetNotificationsAsSeenMutation } from "../../services/auth";
import { IPaginationState } from "../../types/common";
import { getSimplePaginationProps } from "../../utils/table";

const initialPaginationState = {
    pageIndex: 0,
    pageSize: PAGE_SIZES[0],
};

export const useNotifications = () => {
    const [{
        pageIndex,
        pageSize,
    }, setPagination] = useState<IPaginationState>(initialPaginationState);

    const {
        data,
        isLoading: isLoadingGetNotifications,
        refetch: refreshNotifications,
    } = useGetNotificationsQuery({
        shouldMerge: true,
        pageIndex,
        pageSize,
    });

    const [setNotificationsAsSeen] = useSetNotificationsAsSeenMutation();

    const getMore = useCallback(() => {
        setPagination(prevState => ({
            ...prevState,
            pageIndex: prevState.pageIndex + 1,
        }));
    }, []);

    const hasNotSeenNotifications = useMemo(() => {
        if (!data) return false;
        return data.data.notifications.some(notification => notification.seen);
    }, [data]);

    const isLoading = useMemo(() => {
        return isLoadingGetNotifications;
    }, [isLoadingGetNotifications]);

    useSocketNotifications(SOCKETS_EVENTS.notifications, refreshNotifications);

    return {
        isLoading,
        unreadCount: data?.data.unreadCount || 0,
        notifications: data?.data.notifications || [],
        hasNotSeenNotifications,
        setNotificationsAsSeen,
        getMore,
        refreshNotifications,
        pagination: useMemo(() => {
            if (!data) return {
                canNextPage: false,
            };

            return getSimplePaginationProps(data.data.pagination, "Notifications");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data?.data.pagination]),
    };
};
