import { useMemo, useState } from "react";

import { ColumnDef, getCoreRowModel, PaginationState, useReactTable } from "@tanstack/react-table";

import { useGetUsersQuery } from "../../services/users";
import { IUser, UserRole } from "../../types/users";
import { getPaginationProps } from "../../utils/table";

export const useUserSelector = (columns: ColumnDef<IUser>[], filters: { role: UserRole }) => {
    const [{
        pageIndex,
        pageSize,
    }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });

    const paginationData = useMemo(
        () => ({ pageIndex, pageSize }),
        [pageIndex, pageSize]
    );

    const {
        currentData,
        isLoading,
        isFetching,
    } = useGetUsersQuery({
        pageIndex,
        pageSize,
        role: filters.role,
    });

    const table = useReactTable(
        {
            columns: useMemo(() => columns, [columns]),
            data: useMemo(() => currentData?.users || [], [currentData?.users]),
            pageCount: currentData?.pagination.totalPages,
            state: {
                pagination: paginationData,
            },
            onPaginationChange: setPagination,
            getCoreRowModel: getCoreRowModel(),
            manualPagination: true,
        }
    );

    return {
        isLoading: isLoading || isFetching,
        table,
        pagination: useMemo(() => {
            return getPaginationProps(table, currentData?.pagination, "Users");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [paginationData, currentData?.pagination]),
    };
};
