import { useState } from "react";

import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { DatePicker, Input } from "antd";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { useDebouncedCallback } from "use-debounce";

import { IGlobalFilterProps } from "../../../../types/common";
import UserSelector from "../../../inputs/Fields/UserSelector/UserSelector";

const DATE_FORMAT = "DD-MM-YYYY";

const CSV_TABLE_HEADER = [
    { label: "Order Id", key: "id" },
    { label: "Service name", key: "service" },
    { label: "Resident name", key: "name" },
    { label: "Phone", key: "phone" },
    { label: "Email", key: "email" },
    { label: "Begin date", key: "beginDate" },
    { label: "Timeframe", key: "timeframe" },
    { label: "Frequency", key: "frequency" },
    { label: "Cost", key: "cost" },
];

const OrdersHeader = ({
    globalFilter,
    selectedUserId,
    setSelectedUserId,
    selectedDateRange,
    setSelectedDateRange,
    withExport = false,
}: {
    selectedUserId: string | undefined;
    setSelectedUserId: (_selectedUserId: string | undefined) => void;
    globalFilter: IGlobalFilterProps;
    selectedDateRange: [string, string];
    setSelectedDateRange: (_selectedLastSubmittedRange: [string, string]) => void;
    withExport?: boolean;
}) => {
    const [globalFilterValue, setGlobalFilterValue] = useState(globalFilter.globalFilter);
    //const [csvData, setCsvData] = useState<any[]>([]);

    const handleSearchChange = useDebouncedCallback((value: string) => {
        return globalFilter.setGlobalFilter(value);
    }, 500);

    //work with data for export in csv format
    // const {
    //     currentData,
    // } = useGetOrdersQuery({
    //     pageIndex: 0,
    //     pageSize: 100,
    //     globalFilter: globalFilter.globalFilter,
    //     status: "new",
    //     user: selectedUserId,
    //     dateRange: selectedDateRange,
    // });

    // useEffect(() => {
    //     if (currentData && currentData.data.orders) {
    //         const formattedData = currentData.data.orders.map(order => ({
    //             id: order.id,
    //             service: order.service.info.name,
    //             name: `${order.user.info.firstName} ${order.user.info.lastName}`,
    //             phone: order.user.info.phone,
    //             email: order.user.info.email,
    //             beginDate: order.subscription?.beginDate ? new Date(order.subscription?.beginDate).toLocaleDateString() : "",
    //             timeframe: order.subscription?.info.timeframe,
    //             frequency: order.subscription?.info.frequency,
    //             cost: order.subscription?.info.price,
    //         }));
    //         setCsvData(formattedData);
    //     }
    // }, [currentData]);

    return <div className={"p10 space-between"}>
        <div className={"space-between"}>
            <div className={"fs16 bold mr30"}>
                Orders request list
            </div>
            { withExport && <div>
                <button style={{
                    backgroundColor: "#bb2e2e",
                    color: "white",
                    border: "none",
                    borderRadius: 8,
                    padding: "10px 15px",
                    cursor: "pointer",
                }}>
                    <CSVLink
                        data={[]}
                        headers={CSV_TABLE_HEADER}
                        filename={`orders_service_request_list_${new Date().toLocaleDateString()}`}
                        style={{ color: "white" }}
                    >
                        Export data
                    </CSVLink>
                </button>
            </div> }
        </div>
        <div className={"horizontal-right"}>
            <div>
                <div>
                    <DatePicker.RangePicker
                        size={"large"}
                        value={[
                            selectedDateRange[0] ? dayjs(selectedDateRange[0], DATE_FORMAT) : null,
                            selectedDateRange[1] ? dayjs(selectedDateRange[1], DATE_FORMAT) : null,
                        ]}
                        presets={[
                            {
                                label: "Today",
                                value: [dayjs(), dayjs()],
                            }, {
                                label: "Yesterday",
                                value: [dayjs().add(-1, "days"), dayjs().add(-1, "days")],
                            }, {
                                label: "Last 3 Days",
                                value: [dayjs().subtract(3, "days"), dayjs()],
                            }, {
                                label: "Last 7 Days",
                                value: [dayjs().subtract(7, "days"), dayjs()],
                            }, {
                                label: "Last 14 Days",
                                value: [dayjs().subtract(14, "days"), dayjs()],
                            }, {
                                label: "Last 30 Days",
                                value: [dayjs().subtract(30, "days"), dayjs()],
                            }, {
                                label: "Last 90 Days",
                                value: [dayjs().subtract(90, "days"), dayjs()],
                            }, {
                                label: "This Month",
                                value: [dayjs().startOf("month"), dayjs()],
                            }, {
                                label: "This Year",
                                value: [dayjs().startOf("year"), dayjs()],
                            },
                        ]}
                        onChange={dates => {
                            if (dates) {
                                const [from, to] = dates;
                                setSelectedDateRange([from?.format(DATE_FORMAT) || "", to?.format(DATE_FORMAT) || ""]);
                            } else {
                                setSelectedDateRange(["", ""]);
                            }
                        }}
                    />
                </div>
            </div>
            <div className={"ml20"}>
                <UserSelector
                    width={240}
                    role={"resident"}
                    selectedUserId={selectedUserId}
                    onUserSelect={value => setSelectedUserId(value)}
                />
            </div>
            <div className={"ml20"}>
                <Input
                    value={globalFilterValue}
                    size="large"
                    placeholder="Search"
                    allowClear={{
                        clearIcon: <div
                            onClick={() => {
                                setGlobalFilterValue("");
                                globalFilter.setGlobalFilter("");
                            }}
                        >
                            <CloseCircleOutlined/>
                        </div>,
                    }}
                    onChange={e => {
                        setGlobalFilterValue(e.target.value);
                        handleSearchChange(e.target.value);
                    }}
                    prefix={<SearchOutlined/>}
                />
            </div>

            {/*<div className={"ml20"}>*/}
            {/*    <Button*/}
            {/*        type={"primary"}*/}
            {/*        className={"primary-button"}*/}
            {/*        style={{ width: 140, height: 36 }}*/}
            {/*        icon={<PlusOutlined/>}*/}
            {/*        onClick={openCreateOrderModal}*/}
            {/*    >*/}
            {/*        Add order*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>
    </div>;
};

export default OrdersHeader;
