import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";

import Actions from "./components/Actions";
import Cell from "./components/Cell";
import Header from "./components/Header";
import { COMMON_DATE_FORMAT } from "../../../../../constants/date";

export const getColumns = ({
    deleteBanner,
}: {
    deleteBanner: (_data: any) => void
}): ColumnDef<any>[] => [
    {
        id: "title",
        accessorKey: "info.title",
        header: () => <Header label={"Title"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "subtitle",
        accessorKey: "info.subtitle",
        header: () => <Header label={"Subtitle"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "createdAt",
        accessorKey: "createdAt",
        header: () => <Header label={"Created"}/>,
        cell: info => <Cell value={dayjs(info.getValue() as string).format(COMMON_DATE_FORMAT)}/>,
    },
    {
        id: "actions",
        cell: ({ row }) => <Actions
            value={row.original}
            deleteBanner={deleteBanner}
        />,
    },
];
