import { api } from "./connection/api";
import { IGenerateCustomInvoiceBody, IGenerateCustomInvoiceResponse } from "../types/transactions";

const ENDPOINT_PREFIX = "transactions";

export const transactionsApi = api.injectEndpoints({
    endpoints: build => ({
        generateCustomInvoice: build.mutation<IGenerateCustomInvoiceResponse, IGenerateCustomInvoiceBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/custom-invoice`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["Invoice", "Users"],
        }),
    }),

});

export const {
    useGenerateCustomInvoiceMutation,
} = transactionsApi;
