import { useCallback, useState } from "react";

import { Col, Form, FormInstance, Row } from "antd";

import { useUploadFileMutation } from "../../../../services/files";
import { IBannerForm } from "../../../../types/banners";
import { IFile } from "../../../../types/files";
import FileUpload from "../../../common/Files/FileUpload";
import CommonTextArea from "../../../inputs/Form/Inputs/CommonTextArea";
import NameInput from "../../../inputs/Form/Inputs/NameInput";

const BannerForm = ({
    form,
    initialValues,
    onFinish,
}: {
    form: FormInstance;
    initialValues: IBannerForm;
    onFinish: (_values: IBannerForm) => void;
}) => {
    const [imageData, setImageData] = useState<IFile | undefined>();

    const [uploadFileAsync] = useUploadFileMutation();

    const uploadFile = useCallback(async (file: File) => {
        const uploadedFileResponse = await uploadFileAsync({
            file: file,
            path: "services/images",
        }).unwrap();

        if (!uploadedFileResponse?.data?.url) return "";

        return uploadedFileResponse.data.url;
    }, [uploadFileAsync]);

    return <Form
        form={form}
        layout={"vertical"}
        initialValues={initialValues}
        onFinish={values => onFinish({
            ...values,
            image: imageData,
        })}
    >
        <Row gutter={[32, 32]}>
            <Col span={14}>
                <div>
                    <NameInput
                        name={"title"}
                        label={"Title"}
                        required
                    />
                </div>

                <div>
                    <CommonTextArea
                        required
                        rows={4}
                        label={"Subtitle"}
                        name={"subtitle"}
                        placeholder={"Input subtitle..."}
                    />
                </div>

                <div>
                    <NameInput
                        name={"buttonText"}
                        label={"Button text"}
                        required
                    />
                </div>

                <div>
                    <NameInput
                        name={"link"}
                        label={"Link"}
                        required
                    />
                </div>
            </Col>

            <Col span={10}>
                <div>
                    {!imageData ? <FileUpload
                        height={324}
                        name={"banner-image"}
                        uploadFile={uploadFile}
                        file={imageData}
                        setFile={setImageData}
                    /> : <div>
                        <div>
                            <img
                                src={imageData.url}
                                width={"100%"}
                                alt={"banner"}
                            />
                        </div>

                        <div className={"mt10 horizontal-right"}>
                            <div
                                className={"red-color pointer"}
                                onClick={() => {
                                    setImageData(undefined);
                                }}
                            >
                                Remove
                            </div>
                        </div>
                    </div>}
                </div>
            </Col>
        </Row>
    </Form>;
};

export default BannerForm;
