import React from "react";

import { IPageTabItem } from "../../../types/common";

const PageFilterTab = ({
						   isCurrent,
    item,
	 onChange,
}: {
	isCurrent: boolean;
	item: IPageTabItem;
	onChange: (_value: string) => void;
}) => {
    return <div
        	className={`${isCurrent ? "primary-color" : "grey-7-color"} ml30 pointer bold`}
	        style={{ zIndex: 11 }}
		    onClick={() => onChange(item.id)}
	    >
		    {item.title}
	    </div>;
};

export default PageFilterTab;
