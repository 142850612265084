export const getRootPath = (pathname: string) => {
    return pathname.split("/")[1];
};

export const getUserInitials = (name: string) => {
    const [firstName, lastName] = name.split(" ");

    return `${firstName?.slice(0, 1)}${lastName?.slice(0, 1) || ""}`;
};

export const getShortenedName = (str: string, nameMaxLength = 30) => {
    const name = str.substring(0, nameMaxLength);

    return `${name}${name.length < str.length ? "..." : ""}`;
};
