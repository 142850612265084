import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { authApi, getUserData } from "../../services/auth";
import { IAuthState, IGetUserDataResponse, IUpdateCurrentUserDataResponse } from "../../types/auth";
import { IGetNotificationsResponse } from "../../types/notifications";
import { IUser } from "../../types/users";

const emptyUser: IUser = {
    id: "",
    info: {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
    },
    createdAt: "",
    payments: {
        customerId: "",
        maintenance: {
            priceId: "",
            productId: "",
            planId: "",
            subscriptionId: "",
        },
        invoices: [],
    },
};

const initialState: IAuthState = {
    user: emptyUser,
    authData: undefined,
    isAuthInProgress: false,
    unreadCount: 0,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        signOut: () => initialState,
        setShouldChangePassword: (state, action: PayloadAction<boolean>) => {
            state.authData!.shouldChangePassword = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addMatcher(authApi.endpoints.getUserData.matchFulfilled, (state, action: PayloadAction<IGetUserDataResponse>) => {
                const {
                    _id,
                    shouldChangePassword,
                    role,
                    ...rest
                } = action.payload.user;

                state.authData = { _id, shouldChangePassword, role };
                state.user = rest;
            })
            .addMatcher(authApi.endpoints.updateCurrentUser.matchFulfilled, (state, action: PayloadAction<IUpdateCurrentUserDataResponse>) => {
                const {
                    _id,
                    // eslint-disable-next-line no-unused-vars
                    shouldChangePassword,
                    // eslint-disable-next-line no-unused-vars
                    role,
                    ...rest
                } = action.payload.user;

                state.user = rest;
            })
            .addMatcher(authApi.endpoints.getNotifications.matchFulfilled, (state, action: PayloadAction<IGetNotificationsResponse>) => {
                const {
                    unreadCount,
                } = action.payload.data;

                state.unreadCount = unreadCount;
            });
    },
});

export const getCurrentUser = createAsyncThunk(
    "auth/getCurrentUser",
    async (_, { dispatch }) => {
        return dispatch(getUserData.initiate(undefined, {
            subscribe: false,
            forceRefetch: false,
        }));
    }
);

export const login = createAsyncThunk(
    "auth/login",
    async (token: string, { dispatch }) => {
        localStorage.setItem("token", JSON.stringify(`Basic ${token}`));
        dispatch(getCurrentUser());
    }
);

export const logout = createAsyncThunk(
    "auth/logout",
    (_, { dispatch }) => {
        localStorage.removeItem("token");
        dispatch(authSlice.actions.signOut());
    }
);

export default authSlice.reducer;
