import { message, Upload } from "antd";

import { IFile } from "../../../types/files";
import Label from "../../inputs/Form/Labels/Label";

const FileUpload = ({
    name,
    file,
    uploadFile,
    setFile,
    height = 220,
}: {
    height: number;
    name: string;
    file: IFile | undefined;
    uploadFile: (_file: File) => Promise<string>;
    setFile: (_file: IFile) => void;
}) => {
    return <div>
        <div>
            <Label text={"Image"}/>
        </div>

        <div className={"mt8"}>
            <Upload.Dragger
                name={name}
                multiple={false}
                showUploadList={false}
                disabled={!uploadFile}
                accept={".png,.jpg"}
                style={{
                    backgroundColor: "white",
                }}
                customRequest={async data => {
                    const file = data.file as File;
                    const result = await uploadFile?.(file);

                    if (!result) {
                        message.error("File was not uploaded!");
                    }

                    setFile({
                        url: result,
                        originalName: file.name,
                        name: file.name,
                    });

                    return true;
                }}
            >
                <div
                    style={{ width: "100%", minHeight: height }}
                    className={"center"}
                >
                    {!file ? <div>
                        <div className={"drag-component-content"} style={{ width: "100%" }}>
                            <div className={"drag-component-text"}>
                                Drop file or <span className={"primary-color"}>browse</span>.
                            </div>
                        </div>
                    </div> : <div>
                        <div className={"p10 fs14 grey-5-color"}>
                            File has been successfully uploaded.
                        </div>
                        <div className={"p10 fs12 primary-color"}>
                            {file.originalName}
                        </div>
                    </div>}
                </div>
            </Upload.Dragger>
        </div>
    </div>;
};

export default FileUpload;
