import { DeleteOutlined, FileDoneOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";

import { useModalOpener } from "../../../../../../hooks/helpers/useModalOpener";
import { useGenerateCustomInvoiceMutation } from "../../../../../../services/transactions";
import { IDeleteUserBody, IUser } from "../../../../../../types/users";
import CustomInvoiceModal from "../../../../../modals/Residents/CustomInvoiceModal";

const Actions = ({
    value,
    deleteUser,
}: {
	value: IUser;
	deleteUser: (_data: IDeleteUserBody) => void;
}) => {
    const {
        isOpened: customInvoiceModalIsOpened,
        open: openCustomInvoiceModal,
        close: closeCustomInvoiceModal,
    } = useModalOpener();

    const [generateCustomInvoice] = useGenerateCustomInvoiceMutation();

    const onClick: MenuProps["onClick"] = async ({ key }) => {
        if (key === "delete") {
	        deleteUser({
		        id: value.id,
	        });
        } else if (key === "custom-invoice") {
            openCustomInvoiceModal();
        }
    };

    const items: MenuProps["items"] = [{
        key: "custom-invoice",
        icon: <FileDoneOutlined className={"grey-color"}/>,
        label: <span
            data-testid={"generate-custom-invoice"}
            className={"grey-color"}
        >
		    Generate Custom Invoice
        </span>,
    }, {
        key: "delete",
	    icon: <DeleteOutlined className={"red-color"}/>,
	    label: <span
		    data-testid={"delete-resident"}
		    className={"red-color"}
	    >
		    Delete User
        </span>,
    }];

    return <div>
	    {!!items.length && <Dropdown
		    menu={{ items, onClick }}
		    placement="bottomRight"
		    trigger={["click"]}
	    >
            <BsThreeDotsVertical
		        style={{ marginTop: 6 }}
		        className={"fs22 pointer grey-7-color"}
            />
	    </Dropdown>}

        {customInvoiceModalIsOpened && <CustomInvoiceModal
            close={closeCustomInvoiceModal}
            generateCustomInvoice={async items => {
                await generateCustomInvoice({
                    data: {
                        userId: value.id,
                        items,
                    },
                });
            }}
        />}
    </div>;
};

export default Actions;
