import { Table } from "@tanstack/react-table";

import { IGlobalFilterProps, IPagination, IPaginationProps } from "../types/common";

export const getPaginationProps = (table: Table<any>, pagination?: IPagination, itemsName?: string): IPaginationProps => {
    return {
        itemsName: itemsName || "Documents",
        totalDocs: pagination?.totalDocs || 0,
        setPageSize: table.setPageSize,
        pageSize: table.getState().pagination.pageSize,
        pageIndex: table.getState().pagination.pageIndex + 1,
        totalPages: table.getPageCount(),
        previousPage: table.previousPage,
        nextPage: table.nextPage,
        canPreviousPage: table.getCanPreviousPage(),
        canNextPage: table.getCanNextPage(),
        setCurrentPage: table.setPageIndex,
    };
};

export const getSimplePaginationProps = (pagination: IPagination, itemsName?: string): IPaginationProps => {
    const data = {
        itemsName: itemsName || "Documents",
    };

    return {
        ...data,
        totalDocs: pagination.totalDocs || 0,
        pageSize: pagination.limit,
        pageIndex: (pagination.page || 0),
        totalPages: pagination.totalPages,
        previousPage: pagination.prevPage,
        nextPage: pagination.nextPage,
        canPreviousPage: pagination.hasPrevPage,
        canNextPage: pagination.hasNextPage,
    };
};

export const getGlobalFilterProps = (table: Table<any>): IGlobalFilterProps => {
    return {
        setGlobalFilter: table.setGlobalFilter,
        globalFilter: table.getState().globalFilter,
        resetGlobalFilter: table.resetGlobalFilter,
    };
};
