import React from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";

import BannersTable from "./components/BannersTable/BannersTable";
import { getColumns } from "./components/BannersTable/columns";
import { useBannersTable } from "../../../hooks/banners/useBannersTable";
import { useModalOpener } from "../../../hooks/helpers/useModalOpener";
import PageHeader from "../../basic/PageHeader/PageHeader";
import Spinner from "../../basic/Spinner/Spinner";
import Pagination from "../../common/Table/components/Pagination";
import CreateBannerModal from "../../modals/Banners/CreateBannerModal";

const BannerSetup = () => {
    const {
        table,
        pagination,
    } = useBannersTable(getColumns);

    const {
        open: openCreateBannerModal,
        isOpened: createBannerModalIsOpened,
        close: closeCreateBannerModal,
    } = useModalOpener();

    return <div>
        <div>
            <PageHeader menu={[]}/>
        </div>
        <div className={"common-page scroll-block"}>
            <div className={"common-block"}>
                <Spinner isLoading={false}>
                    <div className={"horizontal-right mr20 mt20"}>
                        <div className={"ml20"}>
                            <Button
                                type={"primary"}
                                className={"primary-button"}
                                style={{ width: 140, height: 36 }}
                                icon={<PlusOutlined/>}
                                onClick={openCreateBannerModal}
                            >
                                Add banner
                            </Button>
                        </div>
                    </div>
                    <div className={"mt10"}>
                        <BannersTable
                            table={table}
                        />
                    </div>

                    <div className={"mt10"}>
                        <Pagination
                            pagination={pagination}
                        />
                    </div>
                </Spinner>
            </div>
        </div>
        {createBannerModalIsOpened && <CreateBannerModal
            close={closeCreateBannerModal}
        />}
    </div>;
};

export default BannerSetup;
