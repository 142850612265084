import { useState } from "react";

import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { useDebouncedCallback } from "use-debounce";

import { IGlobalFilterProps } from "../../../../types/common";

const MaintenanceRequestsHeader = ({
    globalFilter,
}: {
    globalFilter: IGlobalFilterProps
}) => {
    const [globalFilterValue, setGlobalFilterValue] = useState(globalFilter.globalFilter);

    const handleSearchChange = useDebouncedCallback((value: string) => {
        return globalFilter.setGlobalFilter(value);
    }, 500);

    return <div className={"p10 space-between"}>
        <div>
            <div className={"fs16 bold"}>
                Maintenance request List
            </div>
        </div>
        <div className={"horizontal-right"}>
            <div>
                <Input
                    value={globalFilterValue}
                    size="large"
                    placeholder="Search"
                    allowClear={{
                        clearIcon: <div
                            onClick={() => {
                                setGlobalFilterValue("");
                                globalFilter.setGlobalFilter("");
                            }}
                        >
                            <CloseCircleOutlined />
                        </div>,
                    }}
                    onChange={e => {
                        setGlobalFilterValue(e.target.value);
                        handleSearchChange(e.target.value);
                    }}
                    prefix={<SearchOutlined />}
                />
            </div>
        </div>
    </div>;
};

export default MaintenanceRequestsHeader;
