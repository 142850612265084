import { useCallback } from "react";

import { Button, Form, message, Modal } from "antd";

import BannerForm from "./components/BannerForm";
import { useGetBannerQuery, useUpdateBannerMutation } from "../../../services/banners";
import { IBannerForm } from "../../../types/banners";
import Spinner from "../../basic/Spinner/Spinner";

const EditBannerModal = ({
    close,
    bannerId,
}: {
    close: () => void;
    bannerId: string;
}) => {
    const [form] = Form.useForm();

    const [updateBanner, {
        isLoading: isLoadingUpdateBanner,
    }] = useUpdateBannerMutation();

    const {
        data,
        isLoading: isLoadingGetBanner,
    } = useGetBannerQuery({
        id: bannerId,
    });

    const onFinish = useCallback(async (values: IBannerForm) => {
        if (!values.image) {
            return message.error("Upload image!");
        }

        await updateBanner({
            id: bannerId,
            data: {
                info: {
                    title: values.title,
                    subtitle: values.subtitle,
                    link: values.link,
                    buttonText: values.buttonText,
                },
                image: values.image,
            },
        });
        close();
    }, [close, bannerId, updateBanner]);

    return <Modal
        open
        width={900}
        title={
            <div className={"p20 modal-header grey-7-color"}>
                Edit Banner
            </div>
        }
        afterClose={() => form.resetFields()}
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
                    Cancel
                </Button>

                <Button
                    htmlType="submit"
                    onClick={() => form.submit()}
                    type={"primary"}
                    className={"primary-button"}
                >
                    Save
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <Spinner isLoading={isLoadingGetBanner || isLoadingUpdateBanner}>
                {data && <BannerForm
                    form={form}
                    initialValues={{
                        title: data.data.banner.info.title,
                        subtitle: data.data.banner.info.subtitle,
                        link: data.data.banner.info.link,
                        buttonText: data.data.banner.info.buttonText,
                    }}
                    onFinish={onFinish}
                />}
            </Spinner>
        </div>
    </Modal>;
};

export default EditBannerModal;
