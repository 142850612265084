import { flexRender } from "@tanstack/react-table";
import { Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

import { columns } from "./columns";
import { useUserSelector } from "../../../../hooks/users/useUserSelector";
import { UserRole } from "../../../../types/users";

export const UserSelector = ({
    disabled = false,
    selectedUserId,
    onUserSelect,
    role,
    width = 240,
    size = "large",
    name = "users",
}: {
    disabled?: boolean;
    selectedUserId?: string;
    role: UserRole;
    onUserSelect: (_userId: string | undefined) => void;
    width?: number | string;
    size?: SizeType;
    name?: string;
}) => {
    const {
        isLoading,
        table,
        pagination,
    } = useUserSelector(columns, {
        role,
    });

    return <div data-testid={name}>
        <Select
            value={selectedUserId}
            onSelect={(value: string) => onUserSelect(value)}
            size={size}
            className={"select-content grey-color"}
            style={{ width }}
            disabled={disabled}
            loading={isLoading}
            allowClear
            onClear={() => onUserSelect(undefined)}
            placeholder={<span className={"fs14"}>Select user...</span>}
            popupMatchSelectWidth
            onPopupScroll={e => {
                e.persist();
                const target = e.target as HTMLElement;
                if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
                    pagination.canNextPage && pagination.nextPage();
                }
            }}
        >
            {table.getRowModel().rows.map(row => {
                return <Select.Option
                    key={row.id}
                    value={row.original.id}
                    className={"select-option"}
                >
                    <div
                        className={"vertical-center"}
                        data-testid={`select-option-${name}-${row.original.info.email}`}
                    >
                        {row.getVisibleCells().map(cell => (
                            <div
                                key={cell.id}
                                className={"fs14 grey-7-color"}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </div>
                        ))}
                    </div>
                </Select.Option>;
            })}
        </Select>
    </div>;
};

export default UserSelector;
