import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";

import Actions from "./components/Actions";
import Cell from "./components/Cell";
import Header from "./components/Header";
import { COMMON_DATE_FORMAT } from "../../../../../constants/date";
import { IDeleteServiceBody, IService } from "../../../../../types/services";

export const getColumns = ({
    deleteService,
}: {
    deleteService: (_data: IDeleteServiceBody) => void
}): ColumnDef<IService>[] => [
    {
        id: "name",
        accessorKey: "info.name",
        header: () => <Header label={"Service"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "description",
        accessorKey: "info.description",
        header: () => <Header label={"Description"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "createdAt",
        accessorKey: "createdAt",
        header: () => <Header label={"Created"}/>,
        cell: info => <Cell value={dayjs(info.getValue() as string).format(COMMON_DATE_FORMAT)}/>,
    },
    {
        id: "actions",
        cell: ({ row }) => <Actions
            value={row.original}
            deleteService={deleteService}
        />,
    },
];
