import React from "react";

import { Avatar, Dropdown } from "antd";

import { useProfile } from "../../../hooks/auth/useProfile";
import { useAppDispatch } from "../../../redux/hooks";
import { logout } from "../../../redux/reducers/auth";
import NotificationsButton from "../../common/Header/NotificationsButton";

const PageHeader = ({
    menu = [],
    rightMenu = [],
}: {
    menu?: React.ReactNode[];
    rightMenu?: React.ReactNode[];
}) => {
    const dispatch = useAppDispatch();

    const {
        user,
        initials,
    } = useProfile();

    return <div
        data-testid="header"
        className={"page-header"}
    >
        <div
            className={"page-header-block space-between white-background-color"}
        >
            <div className={"horizontal-left"}>
                {menu}
            </div>

            <div className={"horizontal-right"}>
                {rightMenu && <div className={"vertical-center mr20"}>
                    {rightMenu}
                </div>}

                <div>
                    <NotificationsButton/>
                </div>

                <div
                    data-testid="top-right-dropdown"
                    className={"pointer mr20 ml20"}
                >
                    <Dropdown
                        overlayStyle={{
                            paddingTop: 6,
                        }}
                        menu={{
                            items: [{
                                label: <div
                                    data-testid={"logout"}
                                    className={"bold"}
                                    onClick={() => {
                                        dispatch(logout());
                                    }}
                                >
                                    Sign out
                                </div>,
                                key: "0",
                            }],
                        }}
                        trigger={["click"]}
                    >
                        <div
                            className={"horizontal-right"}
                        >
                            <div>
                                <Avatar
                                    size="large"
                                >
                                    {initials}
                                </Avatar>
                            </div>
                            <div className={"ml20"}>
                                <div
                                    className={"bold horizontal-right"}
                                >
                                    {user.info.firstName} {user.info.lastName}
                                </div>
                            </div>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    </div>;
};

export default PageHeader;
