import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";

import Actions from "./components/Actions";
import BooleanCell from "./components/BooleanCell";
import Cell from "./components/Cell";
import Description from "./components/Description";
import Header from "./components/Header";
import { COMMON_DATE_FORMAT } from "../../../../../constants/date";
import { IDeleteMaintenanceRequestBody, IMaintenanceRequest } from "../../../../../types/maintenance-requests";

export const getColumns = ({
    deleteMaintenanceRequest,
}: {
    deleteMaintenanceRequest: (_data: IDeleteMaintenanceRequestBody) => void
}): ColumnDef<IMaintenanceRequest>[] => [
    {
        id: "name",
        accessorFn: row => {
            return `${row.user.info.firstName} ${row.user.info.lastName}`;
        },
        header: () => <Header label={"Request"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },

    {
        id: "property",
        accessorKey: "property.address.state",
        header: () => <Header label={"Address"}/>,
        cell: ({ row }) => <Cell style={{ maxWidth: 250, wordWrap: "break-word" }} value={row.original.property?.address ? `${row.original.property?.address?.street}, ${row.original.property?.address?.state}, ${row.original.property?.address?.city}, ${row.original.property?.address?.country}` : "-"} />,
    },
    {
        id: "urgency",
        accessorKey: "info.urgency",
        header: () => <Header label={"Urgency"}/>,
        cell: info => <Cell style={{ minWidth: 120 }} value={info.getValue() as string}/>,
    },
    {
        id: "presentness",
        accessorKey: "info.presentness",
        header: () => <Header label={"Presentness"}/>,
        cell: info => <BooleanCell value={info.getValue() as boolean}/>,
    },
    {
        id: "createdAt",
        accessorKey: "createdAt",
        header: () => <Header label={"Created"}/>,
        cell: info => <Cell value={dayjs(info.getValue() as string).format(COMMON_DATE_FORMAT)}/>,
    },
    {
        id: "completedAt",
        accessorKey: "completedAt",
        header: () => <Header label={"Completed"}/>,
        cell: info => <Cell value={info.getValue() ? dayjs(info.getValue() as string).format(COMMON_DATE_FORMAT) : "-"}/>,
    },
    {
        id: "description",
        accessorKey: "info.description",
        header: () => <Header label={"Resident Notes"}/>,
        cell: info => <Description value={info.getValue() as string}/>,
    },
    {
        id: "actions",
        cell: ({ row }) => <Actions
            value={row.original}
            deleteMaintenanceRequest={deleteMaintenanceRequest}
        />,
    },
];
