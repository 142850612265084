import React, { useContext, useMemo } from "react";

import { NavLink } from "react-router-dom";

import NavigationTooltip from "./NavigationTooltip";
import { getRootPath } from "../../../../utils/strings";
import { NavigationContext } from "../contexts/NavigationContext";

const NavigationItem = ({
    label,
    icon,
    path,
    value,
}: {
	label: string;
	path: string;
	icon: React.ReactNode;
    value?: number;
}) => {
    const {
        navbarIsCollapsed,
	    selectedPath,
	    onPathSelect,
    } = useContext(NavigationContext);

    const itemClassName = useMemo(() => {
        return getRootPath(selectedPath) === getRootPath(path) ? "selected-navigation-item" : "navigation-item";
    }, [path, selectedPath]);

    return <div>
		    <NavLink
			    to={path}
			    data-testid={path}
			    onClick={() => onPathSelect(path)}
		    >
			    {navbarIsCollapsed
				    ? <div className={itemClassName}>
					    <NavigationTooltip title={label}>
					    <div
                            style={{
                                paddingTop: 10,
                                paddingLeft: 8,
                            }}
						    className={"fs18"}
					    >
					        {icon}
				        </div>
                    </NavigationTooltip>
				    </div>
				    : <div className={`${itemClassName} vertical-center`}>
				    <div
                        style={{
                            paddingTop: 4,
                        }}
					    className={"fs20 bold"}
				    >
					    {icon}
                    </div>

                    <div className={"ml10 mr20 space-between"} style={{ width: "100%" }}>
                        <div
                            className={"fs14"}
                        >
                            {label}
                        </div>

                        {!!value && <div
                            className={"fs12 bold primary-color"}
                            style={{
                                backgroundColor: "white",
                                padding: "0px 8px",
                                borderRadius: 8,
                            }}
                        >
                            {value}
                        </div>}
                    </div>

                </div>}
        </NavLink>
    </div>;
};

export default NavigationItem;
