import { useCallback } from "react";

import { Button, Form, message, Modal } from "antd";

import ServiceForm from "./components/ServiceForm";
import { SERVICE_KINDS } from "../../../constants/services";
import { FREQUENCY_KEYS, SUBSCRIPTION_KINDS } from "../../../constants/subscriptions";
import { useCreateServiceMutation } from "../../../services/services";
import { IServiceForm } from "../../../types/services";
import { FrequencyType, SubscriptionKind } from "../../../types/subscriptions";
import Spinner from "../../basic/Spinner/Spinner";

const getInitialPrices = () => SUBSCRIPTION_KINDS.reduce((acc, kind) => {
    FREQUENCY_KEYS.forEach(item => {
        acc[`${item}^${kind.id}`] = 0;
    });

    return acc;
}, {} as {
    [_key: string]: number;
});

const initialValues: IServiceForm = {
    name: "",
    description: "",
    kind: SERVICE_KINDS[0].id,
    prices: getInitialPrices(),
};

const CreateServiceModal = ({
    close,
}: {
    close: () => void;
}) => {
    const [form] = Form.useForm();

    const [createService, {
        isLoading,
    }] = useCreateServiceMutation();

    const onFinish = useCallback(async (values: IServiceForm) => {
        if (!values.image) {
            return message.error("Upload image!");
        }

        await createService({
            data: {
                info: {
                    name: values.name,
                    description: values.description,
                },
                kind: values.kind,
                image: values.image,
                prices: Object.entries(values.prices).flatMap(([key, value]) => {
                    const [frequency, kind] = key.split("^");
                    return {
                        kind: kind as SubscriptionKind,
                        frequency: frequency as FrequencyType,
                        value,
                    };
                }),
            },
        });
        close();
    }, [close, createService]);

    return <Modal
        open
        width={900}
        title={
            <div className={"p20 modal-header grey-7-color"}>
				Add Service
            </div>
        }
        afterClose={() => form.resetFields()}
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
					Cancel
                </Button>

                <Button
                    htmlType="submit"
                    onClick={() => form.submit()}
                    type={"primary"}
                    className={"primary-button"}
                >
					Add
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <Spinner isLoading={isLoading}>
                <ServiceForm
                    form={form}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    isEdit={false}
                />
            </Spinner>
        </div>
    </Modal>;
};

export default CreateServiceModal;
