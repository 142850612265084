import React from "react";
import { createRoot } from "react-dom/client";

import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { store } from "./redux/store";
import { WebSocketProvider } from "./WebSocketContext";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#bb2e2e",
                    fontSizeLG: 15,
                },
            }}
        >
            <Provider store={store}>
                <BrowserRouter>
                    <WebSocketProvider>
                        <App/>
                    </WebSocketProvider>
                </BrowserRouter>
            </Provider>
        </ConfigProvider>
    </React.StrictMode>
);
