import { api } from "./connection/api";
import { ISendNotificationsToUsersBody, ISendNotificationsToUsersResponse } from "../types/push-notifications";

const ENDPOINT_PREFIX = "push-notifications";

export const pushNotificationsApi = api.injectEndpoints({
    endpoints: build => ({
        sendNotificationsToUsers: build.mutation<ISendNotificationsToUsersResponse, ISendNotificationsToUsersBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/send-notifications`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["PushNotifications"],
        }),
    }),

});

export const {
    useSendNotificationsToUsersMutation,
} = pushNotificationsApi;
