import { api } from "./connection/api";
import {
    ICreateServiceBody,
    ICreateServiceResponse,
    IDeleteServiceBody,
    IDeleteServiceResponse, IGetServiceBody, IGetServiceResponse,
    IGetServicesQuery,
    IGetServicesResponse,
    IUpdateServiceBody,
    IUpdateServiceResponse,
} from "../types/services";
import { getServicesQueryString } from "../utils/queries";

const ENDPOINT_PREFIX = "services";

export const servicesApi = api.injectEndpoints({
    endpoints: build => ({
        getServices: build.query<IGetServicesResponse, IGetServicesQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getServicesQueryString(query)}`,
            }),
            providesTags: (result, _error, _arg) =>
                (result
                    ? [...result.data.services.map(({ id }) => ({ type: "Services" as const, id })), "Services"]
                    : ["Services"]),
        }),
        getService: build.query<IGetServiceResponse, IGetServiceBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "GET",
            }),
            providesTags: ["Service"],
        }),
        createService: build.mutation<ICreateServiceResponse, ICreateServiceBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["Services"],
        }),
        updateService: build.mutation<IUpdateServiceResponse, IUpdateServiceBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: ["Services", "Service"],
        }),
        deleteService: build.mutation<IDeleteServiceResponse, IDeleteServiceBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Services"],
        }),
    }),

});

export const {
    useGetServicesQuery,
    useGetServiceQuery,
    useCreateServiceMutation,
    useUpdateServiceMutation,
    useDeleteServiceMutation,
} = servicesApi;
