export const SERVICE_KINDS: {
    readonly id: string;
    readonly title: string;
}[] = [{
    id: "usual",
    title: "Home Services",
}, {
    id: "lux",
    title: "Luxe",
}];

export const SERVICE_KIND_KEYS = SERVICE_KINDS.map(item => item.id);
