import React, { useMemo } from "react";

import OrdersHeader from "./components/OrdersHeader";
import { getColumns } from "./components/OrdersTable/columns";
import OrdersTable from "./components/OrdersTable/OrdersTable";
import { STATUSES } from "../../../constants/orders";
import { useOrdersTable } from "../../../hooks/orders/useOrdersTable";
import { StatusType } from "../../../types/orders";
import PageHeader from "../../basic/PageHeader/PageHeader";
import Spinner from "../../basic/Spinner/Spinner";
import PageFilterTab from "../../common/PageTab/PageFilterTab";
import Pagination from "../../common/Table/components/Pagination";

const Orders = () => {
    const {
        isLoading,
        table,
        pagination,
        globalFilter,
        status,
        setStatus,
        selectedUserId,
        setSelectedUserId,
        selectedDateRange,
        setSelectedDateRange,
    } = useOrdersTable(getColumns);

    const menu = useMemo(() => {
        return STATUSES.map(item => {
            return <PageFilterTab
                isCurrent={status === item.id}
                key={item.id}
                onChange={(status: string) => setStatus(status as StatusType)}
                item={item}
            />;
        });
    }, [setStatus, status]);

    return <div>
        <div>
            <PageHeader menu={menu}/>
        </div>

        <div className={"common-page scroll-block"}>
            <div className={"common-block"}>
                <Spinner isLoading={isLoading}>
                    <div>
                        <OrdersHeader
                            globalFilter={globalFilter}
                            selectedUserId={selectedUserId}
                            setSelectedUserId={setSelectedUserId}
                            selectedDateRange={selectedDateRange}
                            setSelectedDateRange={setSelectedDateRange}
                        />
                    </div>

                    <div className={"mt10"}>
                        <OrdersTable
                            table={table}
                        />
                    </div>

                    <div className={"mt10"}>
                        <Pagination
                            pagination={pagination}
                        />
                    </div>
                </Spinner>
            </div>
        </div>
    </div>;
};

export default Orders;
