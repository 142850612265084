import { getShortenedName } from "../../../../../../utils/strings";

const Description = ({
    value,
}: {
	value: string;
}) => {
    return <div
        className={"fs12 grey-5-color horizontal-center"}
        style={{
            maxWidth: 600,
        }}
    >
        {getShortenedName(value, 300)}
    </div>;
};

export default Description;
