import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const BooleanCell = ({
    value,
}: {
	value: boolean;
}) => {
    return <div
        className={"horizontal-center"}
    >
        {value ? <div>
            <CheckOutlined className={"green-color"}/>
        </div> : <div>
            <CloseOutlined className={"red-color"}/>
        </div>}
    </div>;
};

export default BooleanCell;
