import React, { useMemo } from "react";

import ServicesHeader from "./components/ServicesHeader";
import { getColumns } from "./components/ServicesTable/columns";
import ServicesTable from "./components/ServicesTable/ServicesTable";
import { SERVICE_KINDS } from "../../../constants/services";
import { useServicesTable } from "../../../hooks/services/useServicesTable";
import { ServiceKind } from "../../../types/services";
import PageHeader from "../../basic/PageHeader/PageHeader";
import Spinner from "../../basic/Spinner/Spinner";
import PageFilterTab from "../../common/PageTab/PageFilterTab";
import Pagination from "../../common/Table/components/Pagination";

const Services = () => {
    const {
        isLoading,
        table,
        pagination,
        globalFilter,
        kind,
        setKind,
    } = useServicesTable(getColumns);

    const menu = useMemo(() => {
        return SERVICE_KINDS.map(item => {
            return <PageFilterTab
                isCurrent={kind === item.id}
                key={item.id}
                onChange={(kind: string) => setKind(kind as ServiceKind)}
                item={item}
            />;
        });
    }, [setKind, kind]);

    return <div>
        <div>
            <PageHeader menu={menu}/>
        </div>

        <div className={"common-page scroll-block"}>
            <div className={"common-block"}>
                <Spinner isLoading={isLoading}>
                    <div>
                        <ServicesHeader
                            globalFilter={globalFilter}
                        />
                    </div>

                    <div className={"mt10"}>
                        <ServicesTable
                            table={table}
                        />
                    </div>

                    <div className={"mt10"}>
                        <Pagination
                            pagination={pagination}
                        />
                    </div>
                </Spinner>
            </div>
        </div>
    </div>;
};

export default Services;
