import { FileProtectOutlined } from "@ant-design/icons";

import { IUserInvoice } from "../../../../../../types/users";

const Invoices = ({
    value,
}: {
	value: IUserInvoice[];
}) => {
    return <div className={"horizontal-center"}>
        {value.length ? value.map(item => {
            return <div className={"mr6"}>
                <FileProtectOutlined
                    className={"fs18 primary-color pointer"}
                    onClick={() => {
                        window.open(item.url, "_blank");
                    }}
                />
            </div>;
        }) : "-"}
    </div>;
};

export default Invoices;
