import React, { useCallback } from "react";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space } from "antd";

import { ICustomInvoiceItem } from "../../../types/transactions";
import { roundToTwoDecimals } from "../../../utils/numbers";

interface ICustomInvoiceFormProps {
    items: ICustomInvoiceItem[];
}

const initialValues: ICustomInvoiceFormProps = {
    items: [],
};

const CustomInvoiceModal = ({
    close,
    generateCustomInvoice,
}: {
    close: () => void;
    generateCustomInvoice: (_data: ICustomInvoiceItem[]) => Promise<void>;
}) => {
    const [form] = Form.useForm();

    const onFinish = useCallback(async (values: ICustomInvoiceFormProps) => {
        await generateCustomInvoice(values.items);

        close();
    }, [close, generateCustomInvoice]);

    return <Modal
        open
        width={900}
        title={
            <div className={"p20 modal-header grey-7-color"}>
                Generate Custom Invoice
            </div>
        }
        afterClose={() => form.resetFields()}
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }}
                className={"space-between"}
            >
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
                    Cancel
                </Button>

                <Button
                    htmlType="submit"
                    onClick={() => form.submit()}
                    type={"primary"}
                    className={"primary-button"}
                >
                    Generate
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <Form
                form={form}
                layout={"vertical"}
                initialValues={initialValues}
                onValuesChange={(changedFields, allFields) => {
                    if (changedFields.items) {
                        const changedIndex = changedFields.items.length - 1;

                        const updatedItems = allFields.items.map((item, i) => {
                            if (i !== changedIndex) return item;

                            const qty = roundToTwoDecimals(parseFloat(item.qty as unknown as string));
                            const price = roundToTwoDecimals(parseFloat(item.price as unknown as string));
                            const amount = roundToTwoDecimals(qty * price) || 0;

                            return {
                                ...item,
                                qty,
                                price,
                                amount,
                            };
                        });

                        form.setFieldValue("items", updatedItems);
                    }
                }}
                onFinish={onFinish}
            >
                <Form.List name="items">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, "title"]}
                                        rules={[{ required: true, message: "Missing title" }]}
                                    >
                                        <Input
                                            placeholder="Title"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "qty"]}
                                        rules={[{ required: true, message: "Missing quantity" }]}
                                    >
                                        <Input
                                            placeholder="Quantity"
                                            type={"number"}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "price"]}
                                        rules={[{ required: true, message: "Missing price" }]}
                                    >
                                        <Input
                                            placeholder="Price"
                                            type={"number"}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, "amount"]}
                                        rules={[{ required: true, message: "Missing amount" }]}
                                    >
                                        <Input
                                            placeholder="Amount"
                                            disabled
                                            type={"number"}
                                        />
                                    </Form.Item>

                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add({ amount: 0 })} block icon={<PlusOutlined />}>
                                        Add invoice item
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </div>
    </Modal>;
};

export default CustomInvoiceModal;
