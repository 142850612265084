import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import { Dropdown, MenuProps } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";

import { useModalOpener } from "../../../../../../hooks/helpers/useModalOpener";
import { IDeleteMaintenanceRequestBody, IMaintenanceRequest } from "../../../../../../types/maintenance-requests";
import EditMaintenanceRequestModal from "../../../../../modals/MaintenanceRequests/EditMaintenanceRequestModal";
import MaintenanceRequestInfoModal from "../../../../../modals/MaintenanceRequests/MaintenanceRequestInfoModal";

const Actions = ({
    value,
    deleteMaintenanceRequest,
}: {
	value: IMaintenanceRequest;
    deleteMaintenanceRequest: (_data: IDeleteMaintenanceRequestBody) => void;
}) => {
    const {
        isOpened: showMaintenanceRequestModalIsOpened,
        open: openShowMaintenanceRequestModal,
        close: closeShowMaintenanceRequestModal,
    } = useModalOpener();

    const {
        isOpened: editMaintenanceRequestModalIsOpened,
        open: openEditMaintenanceRequestModal,
        close: closeEditMaintenanceRequestModal,
    } = useModalOpener();

    const onClick: MenuProps["onClick"] = async ({ key }) => {
        if (key === "delete") {
	        deleteMaintenanceRequest({
		        id: value.id,
	        });
        } else if (key === "show") {
            openShowMaintenanceRequestModal();
        } else if (key === "edit") {
            openEditMaintenanceRequestModal();
        }
    };

    const items: MenuProps["items"] = [{
        key: "show",
        icon: <EyeOutlined className={"grey-7-color"}/>,
        label: <span
            data-testid={"show-service"}
            className={"grey-7-color"}
        >
		    Show Info
        </span>,
    }, {
        key: "edit",
        icon: <EditOutlined className={"grey-7-color"}/>,
        label: <span
            data-testid={"edit-service"}
            className={"grey-7-color"}
        >
		    Edit Maintenance Request
        </span>,
    }, {
        key: "delete",
	    icon: <DeleteOutlined className={"red-color"}/>,
	    label: <span
		    data-testid={"delete-service"}
		    className={"red-color"}
	    >
		    Delete Maintenance Request
        </span>,
    }];

    return <div>
	    {!!items.length && <Dropdown
		    menu={{ items, onClick }}
		    placement="bottomRight"
		    trigger={["click"]}
	    >
            <BsThreeDotsVertical
		        style={{ marginTop: 6 }}
		        className={"fs22 pointer grey-7-color"}
            />
	    </Dropdown>}

        {showMaintenanceRequestModalIsOpened && <MaintenanceRequestInfoModal
            close={closeShowMaintenanceRequestModal}
            maintenanceRequestId={value.id}
        />}

        {editMaintenanceRequestModalIsOpened && <EditMaintenanceRequestModal
            close={closeEditMaintenanceRequestModal}
            maintenanceRequestId={value.id}
        />}
    </div>;
};

export default Actions;
