import { Col, Form, FormInstance, Row } from "antd";

import { STATUSES } from "../../../../constants/orders";
import { IOrderForm } from "../../../../types/orders";
import CommonSelect from "../../../inputs/Form/Inputs/CommomSelect";
import CommonDate from "../../../inputs/Form/Inputs/CommonDate";

const OrderForm = ({
    form,
    initialValues,
    onFinish,
}: {
    form: FormInstance;
    initialValues: IOrderForm;
    onFinish: (_values: IOrderForm) => void;
}) => {
    return <Form
        form={form}
        layout={"vertical"}
        initialValues={initialValues}
        onFinish={values => onFinish(values)}
    >
        <Row gutter={[32, 32]}>
            <Col span={24}>
                <div>
                    <CommonSelect
                        label={"Order status"}
                        name={"status"}
                        options={STATUSES.filter(status => {
                            return status.id !== "cancelled";
                        })}
                    />
                </div>

                <div>
                    <CommonDate
                        label={"Order slot"}
                        name={"slot"}
                    />
                </div>

            </Col>
        </Row>
    </Form>;
};

export default OrderForm;
