import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";

import Actions from "./components/Actions";
import Cell from "./components/Cell";
import Header from "./components/Header";
import Invoices from "./components/Invoices";
import { COMMON_DATE_FORMAT } from "../../../../../constants/date";
import { IDeleteUserBody, IUser } from "../../../../../types/users";

export const getColumns = ({
    deleteUser,
}: {
    deleteUser: (_data: IDeleteUserBody) => void
}): ColumnDef<IUser>[] => [
    {
        id: "name",
        accessorFn: row => {
            return `${row.info.firstName} ${row.info.lastName}`;
        },
        header: () => <Header label={"Resident Name"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "email",
        accessorKey: "info.email",
        header: () => <Header label={"Email"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "phone",
        accessorKey: "info.phone",
        header: () => <Header label={"Phone"}/>,
        cell: info => <Cell value={info.getValue() as string}/>,
    },
    {
        id: "invoices",
        accessorKey: "payments.invoices",
        header: () => <Header label={"Invoices"}/>,
        cell: ({ row }) => <Invoices value={row.original.payments.invoices}/>,
    },
    {
        id: "createdAt",
        accessorKey: "createdAt",
        header: () => <Header label={"Created"}/>,
        cell: info => <Cell value={dayjs(info.getValue() as string).format(COMMON_DATE_FORMAT)}/>,
    },
    {
        id: "actions",
        cell: ({ row }) => <Actions
            value={row.original}
            deleteUser={deleteUser}
        />,
    },
];
