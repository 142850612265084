export const STATUSES = [{
    id: "new",
    title: "New orders",
}, {
    id: "in-progress",
    title: "Orders in progress",
}, {
    id: "completed",
    title: "Completed Services",
}, {
    id: "cancelled",
    title: "Cancelled",
}] as const;

export const STATUS_KEYS = STATUSES.map(item => item.id);

export const ORDER_ITEM_TYPES = ["maintenance-request", "subscription"];
export const ORDER_SOURCE_TYPES = ["product", "service"];
