import { BellOutlined, LoadingOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Empty, List, Popover } from "antd";
import dayjs from "dayjs";

import { useNotifications } from "../../../hooks/auth/useNotifications";
import { INotification } from "../../../types/notifications";

export const NotificationsButton = () => {
    const {
        isLoading,
        unreadCount,
        notifications,
        getMore,
        setNotificationsAsSeen,
        pagination: {
            canNextPage,
        },
    } = useNotifications();

    return <div>
        <Popover
            destroyTooltipOnHide
            placement="bottomRight"
            content={<List
                style={{ overflowY: "auto", maxHeight: 500 }}
                itemLayout="horizontal"
                dataSource={notifications}
                locale={{
                    emptyText: <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={"No new notifications"}
                        style={{ margin: 10 }}
                    />,
                }}
                loading={{
                    spinning: isLoading,
                    indicator: <LoadingOutlined style={{ fontSize: 30 }} spin />,
                }}
                loadMore={!!notifications.length && canNextPage && <div
                    style={{ margin: 10 }}
                    className={"center"}
                >
                    <Button
                        shape={"round"}
                        size={"small"}
                        onClick={() => getMore()}
                        className={"primary-button button-label"}
                    >
                        More...
                    </Button>
                </div>}
                renderItem={(item: INotification) => {
                    const date = dayjs(item.createdAt);

                    return <List.Item
                        style={{
                            padding: "8px 14px 6px 14px",
                            ...!item.seen && {
                                backgroundColor: "rgba(247,248,252,0.9)",
                            },
                        }}
                        extra={<div>
                            <div className={"grey-color"} style={{ fontSize: 12 }}>
                                {date.format("hh:mm a")}
                            </div>
                            <div className={"light-grey-color"} style={{ fontSize: 11 }}>
                                {date.format("DD-MM-YYYY")}
                            </div>
                        </div>}
                    >
                        <List.Item.Meta
                            className={"center"}
                            avatar={<Badge dot={!item.seen}>
                                <Avatar
                                    size={48}
                                    aria-label="User avatar"
                                    alt="User avatar"
                                >
                                    {item.initiator.info.firstName[0]}{item.initiator.info.lastName[0]}
                                </Avatar>
                            </Badge>}
                            title={<div
                                style={{
                                    fontSize: 13,
                                    fontWeight: 700,
                                }}>
                                {item.title}
                            </div>}
                            description={<div
                                style={{
                                    fontSize: 12,
                                }}
                            >
                                {item.description}
                            </div>}
                        />
                    </List.Item>;
                }}
            />}
            trigger="click"
            arrow
            overlayStyle={{ width: 480 }}
            onOpenChange={visible => {
                if (visible && !!unreadCount)
                    setTimeout(() => {
                        setNotificationsAsSeen();
                    }, 2000);
            }}
        >
            <div style={{ cursor: "pointer" }} className={"vertical-center"}>
                <Badge
                    count={unreadCount}
                    size={"small"}
                    color={"red"}
                    offset={[-2, 2]}
                >
                    <BellOutlined className={"fs20 grey-7-color"} />
                </Badge>
            </div>
        </Popover>
    </div>;
};

export default NotificationsButton;
