import "./styles/index.less";
import { createContext } from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import PrivateRoute from "./components/basic/PrivateRoute/PrivateRoute";
import Auth from "./components/pages/Auth/Auth";
import BannerSetup from "./components/pages/BannerSetup/BannerSetup";
import MaintenanceRequests from "./components/pages/MaintenanceRequests/MaintenanceRequests";
import Orders from "./components/pages/Orders/Orders";
import Profile from "./components/pages/Profile/Profile";
import PushNotifications from "./components/pages/PushNotifications/PushNotifications";
import Residents from "./components/pages/Residents/Residents";
import Services from "./components/pages/Services/Services";
import Vendors from "./components/pages/Vendors/Vendors";
import { useAuth } from "./hooks/auth/useAuth";
import { IAuthData } from "./types/auth";

const navbarSize = 48;
const pageHeight = `calc(100vh - ${navbarSize}px)`;

export const AppContext = createContext<{
    authData: IAuthData | undefined,
    isAuth: boolean,
    navbarSize: number,
    pageHeight: string,
}>({
    authData: undefined,
    isAuth: false,
    navbarSize,
    pageHeight,
});

const App = () => {
    const {
        authData,
        isAuth,
    } = useAuth();

    return (
        <AppContext.Provider
            value={{
                authData,
                isAuth,
                pageHeight,
                navbarSize,
            }}
        >
            <Routes>
                <Route
                    path="orders"
                    element={<PrivateRoute component={Orders}/>}
                />

                <Route
                    path="residents"
                    element={<PrivateRoute component={Residents}/>}
                />

                <Route
                    path="vendors"
                    element={<PrivateRoute component={Vendors}/>}
                />

                <Route
                    path="services"
                    element={<PrivateRoute component={Services}/>}
                />

                <Route
                    path="maintenance-requests"
                    element={<PrivateRoute component={MaintenanceRequests}/>}
                />

                <Route
                    path="push-notifications"
                    element={<PrivateRoute component={PushNotifications}/>}
                />

                <Route
                    path="banner-setup"
                    element={<PrivateRoute component={BannerSetup}/>}
                />

                <Route
                    path="profile"
                    element={<PrivateRoute component={Profile}/>}
                />

                <Route path="auth" element={<Auth/>}/>

                <Route
                    path="*"
                    element={<Navigate
                        to={"/orders"}
                        replace={true}
                    />}
                />
            </Routes>
        </AppContext.Provider>
    );
};

export default App;
