import React from "react";

import MaintenanceRequestsHeader from "./components/MaintenanceRequestsHeader";
import { getColumns } from "./components/MaintenanceRequestsTable/columns";
import MaintenanceRequestsTable from "./components/MaintenanceRequestsTable/MaintenanceRequestsTable";
import { useMaintenanceRequestsTable } from "../../../hooks/maintenance-requests/useMaintenanceRequestsTable";
import PageHeader from "../../basic/PageHeader/PageHeader";
import Spinner from "../../basic/Spinner/Spinner";
import Pagination from "../../common/Table/components/Pagination";

const MaintenanceRequests = () => {
    const {
        isLoading,
        table,
        pagination,
        globalFilter,
    } = useMaintenanceRequestsTable(getColumns);

    return <div>
        <div>
            <PageHeader menu={[]}/>
        </div>

        <div className={"common-page scroll-block"}>
            <div className={"common-block"}>
                <Spinner isLoading={isLoading}>
                    <div>
                        <MaintenanceRequestsHeader
                            globalFilter={globalFilter}
                        />
                    </div>

                    <div className={"mt10"}>
                        <MaintenanceRequestsTable
                            table={table}
                        />
                    </div>

                    <div className={"mt10"}>
                        <Pagination
                            pagination={pagination}
                        />
                    </div>
                </Spinner>
            </div>
        </div>
    </div>;
};

export default MaintenanceRequests;
