import { useEffect } from "react";

import { useWebSocket } from "../../WebSocketContext";

const useSocketNotifications = (event: string, callback: () => void) => {
    const socket = useWebSocket();

    useEffect(() => {
        if (socket) {
            socket.on(event, callback);

            return () => {
                socket.off(event, callback);
            };
        }
    }, [event, callback, socket]);
};

export default useSocketNotifications;
