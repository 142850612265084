import qs from "qs";

import { IGetBannersQuery } from "../types/banners";
import { IFilter, ISortBy } from "../types/common";
import { IGetMaintenanceRequestsQuery } from "../types/maintenance-requests";
import { IGetNotificationsQuery } from "../types/notifications";
import { IGetOrdersQuery } from "../types/orders";
import { IGetServicesQuery } from "../types/services";
import { IGetUsersQuery } from "../types/users";

export const withPagination = (pageIndex: number, pageSize: number) => {
    return {
        page: pageIndex,
        limit: pageSize,
    };
};

export const withGlobalFilter = (global: string) => {
    return {
        global: global,
    };
};

export const withFilters = (filters?: IFilter[]) => {
    return {
        filters,
    };
};

export const withSortBy = (sortBy?: ISortBy[]) => {
    return {
        ...sortBy?.[0] && {
            sort: sortBy[0].id,
            desc: sortBy[0].desc ? "desc" : "asc",
        },
    };
};

export const getUsersQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    globalFilter = "",
    role = undefined,
}: IGetUsersQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withGlobalFilter(globalFilter),
        ...role !== undefined && {
            role,
        },
    }, { encode: false });
};

export const getServicesQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    globalFilter = "",
    kind = undefined,
}: IGetServicesQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withGlobalFilter(globalFilter),
        ...kind !== undefined && {
            kind,
        },
    }, { encode: false });
};

export const getOrdersQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    globalFilter = "",
    status,
    user,
    dateRange = ["", ""],
}: IGetOrdersQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withGlobalFilter(globalFilter),
        status,
        user,
        dateRange,
    }, { encode: false });
};

export const getMaintenanceRequestsQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    globalFilter = "",
}: IGetMaintenanceRequestsQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
        ...withGlobalFilter(globalFilter),
    }, { encode: false });
};

export const getNotificationsQueryString = ({
    pageIndex = 1,
    pageSize = 20,
}: IGetNotificationsQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
    }, { encode: false });
};

export const getBannersQueryString = ({
    pageIndex = 1,
    pageSize = 20,
}: IGetBannersQuery) => {
    return qs.stringify({
        ...withPagination(pageIndex, pageSize),
    }, { encode: false });
};
