import React, { useEffect, useState } from "react";

import { Button, Input, Radio, message } from "antd";

import { USER_GROUPS } from "../../../../constants/push-notifications";
import { useSendNotificationsToUsersMutation } from "../../../../services/push-notifications";
import { ISendNotificationsToUsersBody, UserGroup } from "../../../../types/push-notifications";

import type { RadioChangeEvent } from "antd/es/radio";

interface NewPushNotificationProps {
    setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewPushNotification: React.FC<NewPushNotificationProps> = ({ setIsSending }) => {
    const [selectedGroup, setSelectedGroup] = useState<UserGroup | "">("");
    const [messageText, setMessageText] = useState<string>("");

    const [sendNotification, { isLoading }] = useSendNotificationsToUsersMutation();

    const handleRadioChange = (e: RadioChangeEvent) => {
        setSelectedGroup(e.target.value as UserGroup);
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessageText(e.target.value);
    };

    const handleSubmit = async () => {
        if (selectedGroup && message) {
            const data: ISendNotificationsToUsersBody = {
                users: selectedGroup,
                message: messageText,
            };

            try {
                const response = await sendNotification(data).unwrap();
                if (response.success) {
                    message.success("Notification sent successfully");
                } else {
                    message.warning("Notification failed to send");
                }
            } catch (error) {
                console.error("Failed to send notification:", error);
            }
        }
    };

    useEffect(() => {
        setIsSending(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <div className="p10">
            <div>
                Message
            </div>
            <div className="mt10">
                <Input.TextArea
                    rows={4}
                    placeholder="Enter your notification message here"
                    value={messageText}
                    onChange={handleMessageChange}
                />
            </div>
            <div className="mt20">
                Send to
            </div>
            <div className="mt4">
                <Radio.Group onChange={handleRadioChange} value={selectedGroup}>
                    <Radio style={{ display: "block" }} value={USER_GROUPS.ALL_USERS}>All users</Radio>
                    <Radio style={{ display: "block" }} value={USER_GROUPS.RESIDENTS}>Residents</Radio>
                    <Radio style={{ display: "block" }} value={USER_GROUPS.VENDORS}>Vendors</Radio>
                </Radio.Group>
            </div>
            <Button className="mt20" type="primary" onClick={handleSubmit} disabled={isLoading || !message || !selectedGroup}>
                {isLoading ? "Sending..." : "Send Notification"}
            </Button>
        </div>
    );
};

export default NewPushNotification;
