import { Checkbox, Form } from "antd";

import Error from "../Labels/Error";

const CommonCheckbox = ({
    required = false,
    label = null,
    name,
    hidden = false,
    errorText = "",
}: {
    required?: boolean;
    label?: string | null;
    name: string;
    hidden?: boolean;
    errorText?: string;
}) => {
    return <div>
        <Form.Item
            hidden={hidden}
            name={name}
            rules={[
                {
                    validator: (_, value) => {
                        if (!required) return Promise.resolve();

                        return value ? Promise.resolve() : Promise.reject(<Error text={errorText}/>);
                    },
                },
            ]}
            labelAlign={"left"}
            valuePropName="checked"
        >
            <Checkbox className={"vertical-center"}>
                <div style={{ paddingTop: 6 }}>{label}</div>
            </Checkbox>
        </Form.Item>
    </div>;
};

export default CommonCheckbox;
