import { api } from "./connection/api";
import {
    ICreateOrderBody,
    ICreateOrderResponse, IDeleteOrderBody, IDeleteOrderResponse,
    IGetOrderBody,
    IGetOrderResponse,
    IGetOrdersQuery,
    IGetOrdersResponse, IUpdateOrderBody, IUpdateOrderResponse,
} from "../types/orders";
import { getOrdersQueryString } from "../utils/queries";

const ENDPOINT_PREFIX = "orders";

export const ordersApi = api.injectEndpoints({
    endpoints: build => ({
        getOrders: build.query<IGetOrdersResponse, IGetOrdersQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getOrdersQueryString(query)}`,
            }),
            providesTags: (result, _error, _arg) =>
                (result
                    ? [...result.data.orders.map(({ id }) => ({ type: "Orders" as const, id })), "Orders"]
                    : ["Orders"]),
        }),
        getOrder: build.query<IGetOrderResponse, IGetOrderBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "GET",
            }),
            providesTags: ["Order"],
        }),
        createOrder: build.mutation<ICreateOrderResponse, ICreateOrderBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["Orders"],
        }),
        updateOrder: build.mutation<IUpdateOrderResponse, IUpdateOrderBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: ["Orders", "Order"],
        }),
        deleteOrder: build.mutation<IDeleteOrderResponse, IDeleteOrderBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Orders"],
        }),
    }),

});

export const {
    useGetOrdersQuery,
    useGetOrderQuery,
    useCreateOrderMutation,
    useUpdateOrderMutation,
    useDeleteOrderMutation,
} = ordersApi;
