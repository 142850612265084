import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import { Dropdown, MenuProps } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";

import { useModalOpener } from "../../../../../../hooks/helpers/useModalOpener";
import { IBanner, IDeleteBannerBody } from "../../../../../../types/banners";
import BannerInfoModal from "../../../../../modals/Banners/BannerInfoModal";
import EditBannerModal from "../../../../../modals/Banners/EditBannerModal";

const Actions = ({
    value,
    deleteBanner,
}: {
	value: IBanner;
    deleteBanner: (_data: IDeleteBannerBody) => void;
}) => {
    const {
        isOpened: showBannerModalIsOpened,
        open: openShowBannerModal,
        close: closeShowBannerModal,
    } = useModalOpener();

    const {
        isOpened: editBannerModalIsOpened,
        open: openEditBannerModal,
        close: closeEditBannerModal,
    } = useModalOpener();

    const onClick: MenuProps["onClick"] = async ({ key }) => {
        if (key === "delete") {
            deleteBanner({
		        id: value.id,
	        });
        } else if (key === "show") {
            openShowBannerModal();
        } else if (key === "edit") {
            openEditBannerModal();
        }
    };

    const items: MenuProps["items"] = [{
        key: "show",
        icon: <EyeOutlined className={"grey-7-color"}/>,
        label: <span
            data-testid={"show-service"}
            className={"grey-7-color"}
        >
		    Show Info
        </span>,
    }, {
        key: "edit",
        icon: <EditOutlined className={"grey-7-color"}/>,
        label: <span
            data-testid={"edit-service"}
            className={"grey-7-color"}
        >
		    Edit Banner
        </span>,
    }, {
        key: "delete",
	    icon: <DeleteOutlined className={"red-color"}/>,
	    label: <span
		    data-testid={"delete-service"}
		    className={"red-color"}
	    >
		    Delete Banner
        </span>,
    }];

    return <div>
	    {!!items.length && <Dropdown
		    menu={{ items, onClick }}
		    placement="bottomRight"
		    trigger={["click"]}
	    >
            <BsThreeDotsVertical
		        style={{ marginTop: 6 }}
		        className={"fs22 pointer grey-7-color"}
            />
	    </Dropdown>}

        {showBannerModalIsOpened && <BannerInfoModal
            close={closeShowBannerModal}
            bannerId={value.id}
        />}

        {editBannerModalIsOpened && <EditBannerModal
            close={closeEditBannerModal}
            bannerId={value.id}
        />}
    </div>;
};

export default Actions;
