import React from "react";

interface CellProps extends React.HTMLAttributes<HTMLDivElement> {
    value: string;
}

const Cell: React.FC<CellProps> = ({ value, ...props }) => {
    return (
        <div {...props} className={"horizontal-center"}>
            {value}
        </div>
    );
};

export default Cell;
