import { createSlice } from "@reduxjs/toolkit";

import { IPagination } from "../../types/common";
import { IUser } from "../../types/users";

const initialState: {
    users: IUser[],
    pagination: IPagination
} = {
    users: [],
    pagination: {
        totalPages: 0,
        totalDocs: 0,
        limit: 0,
        page: 0,
        prevPage: () => null,
        nextPage: () => null,
        hasPrevPage: false,
        hasNextPage: false,
    },
};

export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {},
});

export default usersSlice.reducer;
