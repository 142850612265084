import { useEffect, useMemo, useState } from "react";

import { ColumnDef, getCoreRowModel, getFilteredRowModel, PaginationState, useReactTable } from "@tanstack/react-table";

import { PAGE_SIZES } from "../../constants/pagination";
import { useDeleteServiceMutation, useGetServicesQuery } from "../../services/services";
import { IDeleteServiceBody, IService, ServiceKind } from "../../types/services";
import { getGlobalFilterProps, getPaginationProps } from "../../utils/table";

export const useServicesTable = (getColumns: ({
    // eslint-disable-next-line no-unused-vars
    deleteService,
}: {
    deleteService: (_data: IDeleteServiceBody) => void
}) => ColumnDef<IService>[]) => {
    const [globalFilter, setGlobalFilter] = useState<string>("");
    const [kind, setKind] = useState<ServiceKind>("usual");

    const [{
        pageIndex,
        pageSize,
    }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: PAGE_SIZES[0],
    });

    const paginationData = useMemo(
        () => ({ pageIndex, pageSize }),
        [pageIndex, pageSize]
    );

    const {
        currentData,
        isLoading: isLoadingGetServices,
        isFetching: isFetchingGetServices,
    } = useGetServicesQuery({
        pageIndex,
        pageSize,
        globalFilter,
        kind,
    });

    const [deleteService, {
        isLoading: isLoadingDeleteService,
    }] = useDeleteServiceMutation();

    useEffect(() => {
        setPagination(prevState => ({
            ...prevState,
            pageIndex: 0,
        }));
    }, [
        globalFilter,
        pageSize,
    ]);

    const table = useReactTable(
        {
            columns: useMemo(() => getColumns({
                deleteService,
            }), [deleteService, getColumns]),
            data: useMemo(() => currentData?.data.services || [], [currentData?.data.services]),
            pageCount: currentData?.data.pagination.totalPages,
            state: {
                pagination: paginationData,
                globalFilter,
            },
            onPaginationChange: setPagination,
            onGlobalFilterChange: setGlobalFilter,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            manualPagination: true,
            manualFiltering: true,
        }
    );

    const isLoading = useMemo(() => {
        return isLoadingGetServices || isFetchingGetServices || isLoadingDeleteService;
    }, [
        isLoadingGetServices,
        isFetchingGetServices,
        isLoadingDeleteService,
    ]);

    return {
        isLoading,
        table,
        kind,
        setKind,
        globalFilter: useMemo(() => {
            return getGlobalFilterProps(table);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [globalFilter]),
        pagination: useMemo(() => {
            return getPaginationProps(table, currentData?.data.pagination, "Services");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [paginationData, currentData?.data.pagination]),
    };
};
