const PushNotificationsHeader = () => {
    return <div className={"p10 space-between"}>
        <div>
            <div className={"fs16 bold"}>
                New Push Notification
            </div>
        </div>
        <div className={"horizontal-right"}>
        </div>
    </div>;
};

export default PushNotificationsHeader;
