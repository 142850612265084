import { createSlice } from "@reduxjs/toolkit";

import { IPagination } from "../../types/common";
import { IService } from "../../types/services";

const initialState: {
    services: IService[],
    pagination: IPagination
} = {
    services: [],
    pagination: {
        totalPages: 0,
        totalDocs: 0,
        limit: 0,
        page: 0,
        prevPage: () => null,
        nextPage: () => null,
        hasPrevPage: false,
        hasNextPage: false,
    },
};

export const servicesSlice = createSlice({
    name: "services",
    initialState,
    reducers: {},
});

export default servicesSlice.reducer;
