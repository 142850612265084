import { useCallback } from "react";

import { Button, Form, Modal } from "antd";
import dayjs, { Dayjs } from "dayjs";

import OrderForm from "./components/OrderForm";
import { useGetOrderQuery, useUpdateOrderMutation } from "../../../services/orders";
import { IOrderForm } from "../../../types/orders";
import Spinner from "../../basic/Spinner/Spinner";

const EditOrderModal = ({
    close,
    orderId,
}: {
    close: () => void;
    orderId: string;
}) => {
    const [form] = Form.useForm();

    const [updateOrder, {
        isLoading: isLoadingUpdateOrder,
    }] = useUpdateOrderMutation();

    const {
        data,
        isLoading: isLoadingGetOrder,
    } = useGetOrderQuery({
        id: orderId,
    });

    const onFinish = useCallback(async (values: IOrderForm) => {
        await updateOrder({
            id: orderId,
            data: {
                info: {
                    slot: (values.slot as Dayjs).toDate(),
                },
                status: values.status,
            },
        });
        close();
    }, [close, orderId, updateOrder]);

    return <Modal
        open
        width={500}
        title={
            <div className={"p20 modal-header grey-7-color"}>
				Edit Order
            </div>
        }
        afterClose={() => form.resetFields()}
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
					Cancel
                </Button>

                <Button
                    htmlType="submit"
                    onClick={() => form.submit()}
                    type={"primary"}
                    className={"primary-button"}
                >
					Save
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <Spinner isLoading={isLoadingGetOrder || isLoadingUpdateOrder}>
                {data && <OrderForm
                    form={form}
                    initialValues={{
                        slot: data.data.order.info.slot ? dayjs(data.data.order.info.slot) : null,
                        status: data.data.order.status,
                    }}
                    onFinish={onFinish}
                />}
            </Spinner>
        </div>
    </Modal>;
};

export default EditOrderModal;
