import { useMemo, useState } from "react";

import { Form } from "antd";

import { useUser } from "./useUser";
import { useUpdateCurrentUserMutation } from "../../services/auth";
import { getUserInitials } from "../../utils/strings";

export const useProfile = () => {
    const { user } = useUser();

    const [form] = Form.useForm();

    const [isEditing, setIsEditing] = useState(false);

    const initials = useMemo(() => {
        return getUserInitials(`${user.info.firstName} ${user.info.lastName}`);
    }, [user.info.firstName, user.info.lastName]);

    const [updateCurrentUser, {
        isLoading,
    }] = useUpdateCurrentUserMutation();

    return {
        user,
        initials,
	    form,
        isLoading,
        isEditing,
        setIsEditing,
        updateCurrentUser,
    };
};
