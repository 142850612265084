import { Button, Col, Modal, Row } from "antd";

import { useGetBannerQuery } from "../../../services/banners";
import Spinner from "../../basic/Spinner/Spinner";

const BannerInfoModal = ({
    close,
    bannerId,
}: {
    close: () => void;
    bannerId: string;
}) => {
    const {
        data,
        isLoading,
    } = useGetBannerQuery({
        id: bannerId,
    });

    if (!data) {
        return <div>
            Loading...
        </div>;
    }

    return <Modal
        open
        width={600}
        title={
            <div className={"p20 modal-header grey-7-color"}>
                Banner Info
            </div>
        }
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    htmlType="submit"
                    onClick={() => close()}
                    type={"primary"}
                    className={"primary-button"}
                >
                    Close
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <Spinner isLoading={isLoading}>
                <div>
                    <div>
                        <img
                            src={data?.data.banner.image.url}
                            width={"100%"}
                            alt={"service"}
                        />
                    </div>

                    <Row className={"mt20"}>
                        <Col span={12}>
                            <div>
                                <div className={"bold"}>
                                    Banner title:
                                </div>

                                <div>
                                    {data?.data.banner.info.title}
                                </div>
                            </div>

                            <div className={"mt20"}>
                                <div className={"bold"}>
                                    Banner subtitle:
                                </div>

                                <div>
                                    {data?.data.banner.info.subtitle}
                                </div>
                            </div>

                            <div className={"mt20"}>
                                <div className={"bold"}>
                                    Button text:
                                </div>

                                <div>
                                    {data?.data.banner.info.buttonText}
                                </div>
                            </div>

                            <div className={"mt20"}>
                                <div className={"bold"}>
                                    Link:
                                </div>

                                <div>
                                    {data?.data.banner.info.link}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Spinner>
        </div>
    </Modal>;
};

export default BannerInfoModal;
