import { Button, Col, Modal, Row } from "antd";

import { useGetServiceQuery } from "../../../services/services";
import Spinner from "../../basic/Spinner/Spinner";

const ServiceInfoModal = ({
    close,
    serviceId,
}: {
    close: () => void;
    serviceId: string;
}) => {
    const {
        data,
        isLoading,
    } = useGetServiceQuery({
        id: serviceId,
    });

    if (!data) {
        return <div>
            Loading...
        </div>;
    }

    return <Modal
        open
        width={600}
        title={
            <div className={"p20 modal-header grey-7-color"}>
				Service Info
            </div>
        }
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    htmlType="submit"
                    onClick={() => close()}
                    type={"primary"}
                    className={"primary-button"}
                >
					Close
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <Spinner isLoading={isLoading}>
                <div>
                    <div>
                        <img
                            src={data?.data.service.image.url}
                            width={"100%"}
                            alt={"service"}
                        />
                    </div>

                    <Row className={"mt20"}>
                        <Col span={12}>
                            <div>
                                <div className={"bold"}>
                                Service name:
                                </div>

                                <div>
                                    {data?.data.service.info.name}
                                </div>
                            </div>

                            <div className={"mt20"}>
                                <div className={"bold"}>
                                    Service description:
                                </div>

                                <div>
                                    {data?.data.service.info.description}
                                </div>
                            </div>

                            <div className={"mt20"}>
                                <div className={"bold"}>
                                    Service kind:
                                </div>

                                <div>
                                    {data?.data.service.kind}
                                </div>
                            </div>
                        </Col>

                        <Col span={12}>
                            <div>
                                <div className={"bold"}>
                                    Prices:
                                </div>

                                <div>
                                    {data?.data.service.prices.map(item => {
                                        return <div className={"mt10"}>
                                            - {item.frequency} ({item.kind}): ${item.value}
                                        </div>;
                                    })}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Spinner>
        </div>
    </Modal>;
};

export default ServiceInfoModal;
