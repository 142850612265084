import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";

import { useGetMaintenanceRequestQuery } from "../../../services/maintenance-requests";
import Spinner from "../../basic/Spinner/Spinner";

const MaintenanceRequestInfoModal = ({
    close,
    maintenanceRequestId,
}: {
    close: () => void;
    maintenanceRequestId: string;
}) => {
    const {
        data,
        isLoading,
    } = useGetMaintenanceRequestQuery({
        id: maintenanceRequestId,
    });

    if (!data) {
        return <div>
            Loading...
        </div>;
    }

    return <Modal
        open
        width={900}
        title={
            <div className={"p20 modal-header grey-7-color"}>
                Maintenance Request Info
            </div>
        }
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    htmlType="submit"
                    onClick={() => close()}
                    type={"primary"}
                    className={"primary-button"}
                >
					Close
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <Spinner isLoading={isLoading}>
                <div>
                    <Row gutter={32}>
                        <Col span={16}>
                            <div>
                                <div className={"bold"}>
                                    Maintenance Request description:
                                </div>

                                <div className={"fs12 grey-6-color"}>
                                    {data?.data.maintenanceRequest.info.description}
                                </div>
                            </div>

                            <div className={"mt20"}>
                                <div className={"bold"}>
                                    Urgency:
                                </div>

                                <div>
                                    {data?.data.maintenanceRequest.info.urgency}
                                </div>
                            </div>

                            <div className={"mt20"}>
                                <div className={"horizontal-left"}>
                                    <div className={"bold"}>
                                        Presentness:
                                    </div>

                                    <div className={"ml10"}>
                                        {data?.data.maintenanceRequest.info.presentness ? <div>
                                            <CheckOutlined className={"green-color"}/>
                                        </div> : <div>
                                            <CloseOutlined className={"red-color"}/>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={8}>
                            <div>
                                <div className={"bold"}>
                                    Files:
                                </div>

                                <div>
                                    {data?.data.maintenanceRequest.files.map(file => {
                                        return <div>
                                            - <span className={"bold"}>{file.name}</span>: <a href={file.url}>Link</a>
                                        </div>;
                                    })}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Spinner>
        </div>
    </Modal>;
};

export default MaintenanceRequestInfoModal;
