import { IUser } from "../../../../../../types/users";

const Contacts = ({
    value,
}: {
	value: IUser;
}) => {
    return <div className={"horizontal-center"}>
        <div>
            <div className={"fs14"}>
                {value.info.phone}
            </div>

            <div className={"fs12 mt4 red-color"}>
                {value.info.email}
            </div>
        </div>

    </div>;
};

export default Contacts;
