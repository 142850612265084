import { useEffect, useState } from "react";

import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { CSVLink } from "react-csv";
import { useDebouncedCallback } from "use-debounce";

import { useGetUsersQuery } from "../../../../services/users";
import { IGlobalFilterProps } from "../../../../types/common";

const CSV_TABLE_HEADER = [
    { label: "Resident name", key: "name" },
    { label: "Phone", key: "phone" },
    { label: "Email", key: "email" },
    { label: "Created at", key: "createdAt" },
];

const VendorsHeader = ({
    globalFilter,
}: {
    globalFilter: IGlobalFilterProps
}) => {
    const [globalFilterValue, setGlobalFilterValue] = useState(globalFilter.globalFilter);
    const [csvData, setCsvData] = useState<any[]>([]);

    const {
        currentData,
    } = useGetUsersQuery({
        pageIndex: 0,
        pageSize: 100,
        globalFilter: "",
        role: "vendor",
    });

    const handleSearchChange = useDebouncedCallback((value: string) => {
        return globalFilter.setGlobalFilter(value);
    }, 500);

    useEffect(() => {
        if (currentData && currentData.users) {
            const formattedData = currentData.users.map(user => ({
                name: `${user.info.firstName} ${user.info.lastName}`,
                phone: user.info.phone,
                email: user.info.email,
                createdAt: user.createdAt,
            }));
            setCsvData(formattedData);
        }
    }, [currentData]);

    return <div className={"p10 space-between"}>
        <div className={"space-between"}>
            <div className={"fs16 bold mr30"}>
                Vendor List
            </div>
            <div>
                <button style={{
                    backgroundColor: "#bb2e2e",
                    color: "white",
                    border: "none",
                    borderRadius: 8,
                    padding: "10px 15px",
                    cursor: "pointer",
                }}>
                    <CSVLink
                        data={csvData}
                        headers={CSV_TABLE_HEADER}
                        filename={`vendors_list_${new Date().toLocaleDateString()}`}
                        style={{ color: "white" }}
                    >
                        Export data
                    </CSVLink>
                </button>
            </div>
        </div>
        <div>
            <Input
                value={globalFilterValue}
                size="large"
                placeholder="Search"
                allowClear={{
                    clearIcon: <div
                        onClick={() => {
                            setGlobalFilterValue("");
                            globalFilter.setGlobalFilter("");
                        }}
                    >
                        <CloseCircleOutlined />
                    </div>,
                }}
                onChange={e => {
                    setGlobalFilterValue(e.target.value);
                    handleSearchChange(e.target.value);
                }}
                prefix={<SearchOutlined />}
            />
        </div>
    </div>;
};

export default VendorsHeader;
