import React, { createContext, useContext } from "react";

import { Avatar, Col, Form, Input, Row } from "antd";
import { AiOutlineEdit, AiOutlineSave } from "react-icons/ai";

import { useProfile } from "../../../hooks/auth/useProfile";
import PageHeader from "../../basic/PageHeader/PageHeader";
import Spinner from "../../basic/Spinner/Spinner";
import EditButton from "../../inputs/Fields/EditButton/EditButton";

const INFO_MAX_WIDTH = 540;

const InfoItemContext = createContext({
    isEditing: false,
});

const InfoItem = ({
    editable = true,
    name,
    title,
    value,
}: {
    editable?: boolean;
    name?: string;
    title: string;
    value?: string;
}) => {
    const { isEditing } = useContext(InfoItemContext);

    return <div>
        <div className={"fs13 grey-5-color"}>
            {title}
        </div>
        {(editable && isEditing) ? <div className={"mt10"}>
            <Form.Item
                wrapperCol={{ flex: "0px" }}
                name={name}
            >
                <Input className={"input-content grey-7-color"}/>
            </Form.Item>
        </div> : <div className={"mt10 fs15 grey-7-color bold"}>
            {value || "-"}
        </div>}
    </div>;
};

const Profile = () => {
    const {
        user,
        initials,
        form,
        isLoading,
        isEditing,
        setIsEditing,
        updateCurrentUser,
    } = useProfile();

    return <div>
        <div>
            <PageHeader menu={[]}/>
        </div>

        <div className={"common-page scroll-block"}>
            <div className={"common-block"}>
                <Spinner isLoading={isLoading}>
                    <div className={"p20"}>
                        <div className={"space-between"}>
                            <div className={"h2"}>
                                My Profile
                            </div>
                            <div>
                                <EditButton
                                    isEditMode={isEditing}
                                    start={{
                                        icon: <AiOutlineEdit/>,
                                        label: "Edit",
                                        action: () => {
                                            setIsEditing(true);
                                        },
                                    }}
                                    finish={{
                                        icon: <AiOutlineSave/>,
                                        label: "Save",
                                        action: () => {
                                            form.submit();
                                            setIsEditing(false);
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"mt20"}>
                            <div
                                className={"border-1px p20"}
                                style={{ borderRadius: 10 }}
                            >
                                <div className={"horizontal-left"}>
                                    <div>
                                        <Avatar
                                            size={80}
                                        >
                                            {initials}
                                        </Avatar>
                                    </div>
                                    <div className={"ml20"}>
                                        <div
                                            className={"fs16 bold"}
                                        >
                                            {user.info.firstName} {user.info.lastName}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"mt20"}>
                            <div
                                className={"border-1px p20"}
                                style={{ borderRadius: 10 }}
                            >
                                <div>
                                    <div className={"fs16 bold"}>
                                        Personal Information
                                    </div>

                                    <div className={"mt20"}>
                                        <Form
                                            form={form}
                                            layout="vertical"
                                            name="userForm"
                                            onFinish={values => {
                                                updateCurrentUser({
                                                    data: {
                                                        firstName: values.firstName,
                                                        lastName: values.lastName,
                                                        phone: values.phone,
                                                        email: values.email,
                                                    },
                                                });
                                            }}
                                            initialValues={{
                                                firstName: user.info.firstName,
                                                lastName: user.info.lastName,
                                                phone: user.info.phone,
                                                email: user.info.email,
                                            }}
                                        >
                                            <InfoItemContext.Provider value={{ isEditing }}>
                                                <div>
                                                    <Row wrap={false} gutter={32} style={{ maxWidth: INFO_MAX_WIDTH }}>
                                                        <Col span={12}>
                                                            <InfoItem
                                                                name={"firstName"}
                                                                title={"First name"}
                                                                value={user.info.firstName}
                                                            />
                                                        </Col>

                                                        <Col span={12}>
                                                            <InfoItem
                                                                name={"lastName"}
                                                                title={"Last name"}
                                                                value={user.info.lastName}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className={"mt20"}>
                                                    <Row wrap={false} gutter={32} style={{ maxWidth: INFO_MAX_WIDTH }}>
                                                        <Col span={12}>
                                                            <InfoItem
                                                                name={"email"}
                                                                title={"Email Address"}
                                                                value={user.info.email}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <InfoItem
                                                                name={"phone"}
                                                                title={"Phone"}
                                                                value={user.info.phone}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </InfoItemContext.Provider>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Spinner>
            </div>
        </div>
    </div>;
};

export default Profile;
