import { Button, Col, Modal, Row } from "antd";
import dayjs from "dayjs";

import { COMMON_DATE_FORMAT } from "../../../constants/date";
import { useGetOrderQuery } from "../../../services/orders";
import { ISubscription } from "../../../types/subscriptions";
import Spinner from "../../basic/Spinner/Spinner";

const OrderInfoModal = ({
    close,
    orderId,
}: {
    close: () => void;
    orderId: string;
}) => {
    const {
        data,
        isLoading,
    } = useGetOrderQuery({
        id: orderId,
    });

    if (!data) {
        return <div>
            Loading...
        </div>;
    }

    return <Modal
        open
        width={600}
        title={
            <div className={"p20 modal-header grey-7-color"}>
				Order Info
            </div>
        }
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    htmlType="submit"
                    onClick={() => close()}
                    type={"primary"}
                    className={"primary-button"}
                >
					Close
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <Spinner isLoading={isLoading}>
                <div>
                    <Row>
                        <Col span={12}>
                            <div>
                                <div className={"bold"}>
                                    Order No:
                                </div>

                                <div>
                                    {data?.data.order.info?.orderNo}
                                </div>
                            </div>

                            <div className={"mt20"}>
                                <div className={"bold"}>
                                    Order cost:
                                </div>

                                <div>
                                    ${data?.data.order.info?.cost}
                                </div>
                            </div>

                            <div className={"mt20"}>
                                <div className={"bold"}>
                                    Order slot:
                                </div>

                                <div>
                                    {data?.data.order.info.slot ? dayjs(data.data.order.info.slot).format(COMMON_DATE_FORMAT) : "-"}
                                </div>
                            </div>
                        </Col>

                        <Col span={12}>
                            <div>
                                <div className={"bold"}>
                                    Resident name:
                                </div>

                                <div>
                                    {data?.data.order.user.info.firstName} {data?.data.order.user.info.lastName}
                                </div>
                            </div>

                            <div className={"mt20"}>
                                <div className={"bold"}>
                                    Service/Product name:
                                </div>

                                <div>
                                    {data?.data.order.source.info.name}
                                </div>
                            </div>

                            {data?.data.order.info.sourceType === "service" && <>
                                <div className={"mt20"}>
                                    <div className={"bold"}>
                                        Service frequency:
                                    </div>

                                    <div>
                                        {(data?.data.order.item as ISubscription)?.info.frequency || "-"}
                                    </div>
                                </div>
                                <div className={"mt20"}>
                                    <div className={"bold"}>
                                        Service timeframe:
                                    </div>

                                    <div>
                                        {(data?.data.order.item as ISubscription)?.info.timeframe || "-"}
                                    </div>
                                </div>
                            </>}
                        </Col>
                    </Row>
                </div>
            </Spinner>
        </div>
    </Modal>;
};

export default OrderInfoModal;
