import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import { Dropdown, MenuProps } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";

import { useModalOpener } from "../../../../../../hooks/helpers/useModalOpener";
import { IDeleteServiceBody, IService } from "../../../../../../types/services";
import EditServiceModal from "../../../../../modals/Services/EditServiceModal";
import ServiceInfoModal from "../../../../../modals/Services/ServiceInfoModal";

const Actions = ({
    value,
    deleteService,
}: {
	value: IService;
    deleteService: (_data: IDeleteServiceBody) => void;
}) => {
    const {
        isOpened: showServiceModalIsOpened,
        open: openShowServiceModal,
        close: closeShowServiceModal,
    } = useModalOpener();

    const {
        isOpened: editServiceModalIsOpened,
        open: openEditServiceModal,
        close: closeEditServiceModal,
    } = useModalOpener();

    const onClick: MenuProps["onClick"] = async ({ key }) => {
        if (key === "delete") {
	        deleteService({
		        id: value.id,
	        });
        } else if (key === "show") {
            openShowServiceModal();
        } else if (key === "edit") {
            openEditServiceModal();
        }
    };

    const items: MenuProps["items"] = [{
        key: "show",
        icon: <EyeOutlined className={"grey-7-color"}/>,
        label: <span
            data-testid={"show-service"}
            className={"grey-7-color"}
        >
		    Show Info
        </span>,
    }, {
        key: "edit",
        icon: <EditOutlined className={"grey-7-color"}/>,
        label: <span
            data-testid={"edit-service"}
            className={"grey-7-color"}
        >
		    Edit Service
        </span>,
    }, {
        key: "delete",
	    icon: <DeleteOutlined className={"red-color"}/>,
	    label: <span
		    data-testid={"delete-service"}
		    className={"red-color"}
	    >
		    Delete Service
        </span>,
    }];

    return <div>
	    {!!items.length && <Dropdown
		    menu={{ items, onClick }}
		    placement="bottomRight"
		    trigger={["click"]}
	    >
            <BsThreeDotsVertical
		        style={{ marginTop: 6 }}
		        className={"fs22 pointer grey-7-color"}
            />
	    </Dropdown>}

        {showServiceModalIsOpened && <ServiceInfoModal
            close={closeShowServiceModal}
            serviceId={value.id}
        />}

        {editServiceModalIsOpened && <EditServiceModal
            close={closeEditServiceModal}
            serviceId={value.id}
        />}
    </div>;
};

export default Actions;
