import React, { createContext, useContext, useEffect, useState } from "react";

import { io, Socket } from "socket.io-client";

import { useUser } from "./hooks/auth/useUser";

const WebSocketContext = createContext<Socket | null>(null);

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);

    const { user } = useUser();

    useEffect(() => {
        if (user) {
            const newSocket = io(`${process.env.REACT_APP_API_URL!.replace("/api/v1", "")}`, {
                query: { userId: user.id },
            });

            newSocket.on("connect", () => {
                console.log("connected to WebSocket server");
            });

            newSocket.on("disconnect", () => {
                console.log("disconnected from WebSocket server");
            });

            newSocket.on("connect_error", error => {
                console.error("Connection error:", error);
            });

            setSocket(newSocket);

            return () => {
                newSocket.disconnect();
            };
        }
    }, [user]);

    return (
        <WebSocketContext.Provider value={socket}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
