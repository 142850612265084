import { api } from "./connection/api";
import {
    IEmail,
    IGetUserDataResponse,
    IResetPassword,
    ISignInRequestBody,
    IUserRequestBody,
} from "../types/auth";
import { IGetNotificationsQuery, IGetNotificationsResponse } from "../types/notifications";
import { getNotificationsQueryString } from "../utils/queries";

const ENDPOINT_PREFIX = "auth";

export const authApi = api.injectEndpoints({
    endpoints: build => ({
        signIn: build.mutation({
            query(data: ISignInRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/sign-in`,
                    method: "POST",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        getUserData: build.query<IGetUserDataResponse, void>({
            query: () => ({
                url: `${ENDPOINT_PREFIX}/`,
            }),
            providesTags: ["Auth"],
        }),
        updateCurrentUser: build.mutation({
            query(data: IUserRequestBody) {
                return {
                    url: `${ENDPOINT_PREFIX}/`,
                    method: "PATCH",
                    body: data.data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        forgotPassword: build.mutation({
            query(data: IEmail) {
                return {
                    url: `${ENDPOINT_PREFIX}/forgot-password`,
                    method: "PUT",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        resetPassword: build.mutation({
            query(data: IResetPassword) {
                return {
                    url: `${ENDPOINT_PREFIX}/reset-password`,
                    method: "PATCH",
                    body: data,
                };
            },
            invalidatesTags: ["Auth"],
        }),
        getNotifications: build.query<IGetNotificationsResponse, IGetNotificationsQuery>({
            query: data => ({
                url: `/${ENDPOINT_PREFIX}/notifications?${getNotificationsQueryString(data)}`,
                method: "GET",
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems, { arg: { shouldMerge, pageIndex } }) => {
                if (shouldMerge && pageIndex !== 0) {
                    currentCache.data.notifications.push(...newItems.data.notifications);
                } else {
                    currentCache.data.notifications = newItems.data.notifications;
                }

                currentCache.data.pagination = newItems.data.pagination;
                currentCache.data.unreadCount = newItems.data.unreadCount;
            },
            forceRefetch({ currentArg, previousArg }) {
                return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
            },
            providesTags: ["Notifications"],
        }),
        setNotificationsAsSeen: build.mutation<void, void>({
            query: () => ({
                url: `/${ENDPOINT_PREFIX}/notifications/seen`,
                method: "PATCH",
            }),
            invalidatesTags: ["Notifications"],
        }),
    }),
});

export const {
    useSignInMutation,
    useUpdateCurrentUserMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useGetUserDataQuery,
    useGetNotificationsQuery,
    useSetNotificationsAsSeenMutation,
    endpoints: {
        getUserData,
    },
} = authApi;
