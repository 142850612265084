import { Form, Select } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

import Label from "../Labels/Label";

const CommonSelect = ({
    size = "large",
    required = false,
    name,
    errorMessage = "Please set value!",
    placeholder = "Select...",
    options,
    label,
}: {
    size?: SizeType;
    required?: boolean;
    name: string;
    errorMessage?: string;
    placeholder?: string;
    label?: string;
    options: {
        readonly id: string;
        readonly title: string;
    }[];
}) => {
    return <div>
        <Form.Item
            name={name}
            label={label ? <Label text={label}/> : null}
            rules={[{
                required,
                message: errorMessage,
            }]}
        >
            <Select
                size={size}
                className={"select-content grey-color"}
                style={{ width: "100%" }}
                placeholder={placeholder}
            >
                {options.map(option => {
                    return <Select.Option
                        key={option.id}
                        value={option.id}
                        className={"select-option"}
                        labelInValue
                    >
                        {option.title}
                    </Select.Option>;
                })}
            </Select>
        </Form.Item>
    </div>;
};

export default CommonSelect;
