import { useEffect, useMemo, useState } from "react";

import { ColumnDef, getCoreRowModel, getFilteredRowModel, PaginationState, useReactTable } from "@tanstack/react-table";

import { PAGE_SIZES } from "../../constants/pagination";
import { useDeleteBannerMutation, useGetBannersQuery } from "../../services/banners";
import { getGlobalFilterProps, getPaginationProps } from "../../utils/table";

export const useBannersTable = (getColumns: ({
    // eslint-disable-next-line no-unused-vars
    deleteBanner,
}: {
    deleteBanner: (_data: any) => void
}) => ColumnDef<any>[]) => {
    const [globalFilter, setGlobalFilter] = useState("");

    const [{
        pageIndex,
        pageSize,
    }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: PAGE_SIZES[0],
    });

    const paginationData = useMemo(
        () => ({ pageIndex, pageSize }),
        [pageIndex, pageSize]
    );

    const {
        currentData,
        isLoading: isLoadingGetBanners,
        isFetching: isFetchingGetBanners,
    } = useGetBannersQuery({
        pageIndex,
        pageSize,
    });

    const [deleteBanner, {
        isLoading: isLoadingDeleteBanner,
    }] = useDeleteBannerMutation();

    useEffect(() => {
        setPagination(prevState => ({
            ...prevState,
            pageIndex: 0,
        }));
    }, [
        globalFilter,
        pageSize,
    ]);

    const table = useReactTable(
        {
            columns: useMemo(() => getColumns({
                deleteBanner,
            }), [deleteBanner, getColumns]),
            data: useMemo(() => currentData?.data.banners || [], [currentData?.data.banners]),
            pageCount: currentData?.data.pagination.totalPages,
            state: {
                pagination: paginationData,
                globalFilter,
            },
            onPaginationChange: setPagination,
            onGlobalFilterChange: setGlobalFilter,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            manualPagination: true,
            manualFiltering: true,
        }
    );

    const isLoading = useMemo(() => {
        return isLoadingGetBanners || isFetchingGetBanners || isLoadingDeleteBanner;
    }, [isFetchingGetBanners, isLoadingDeleteBanner, isLoadingGetBanners]);

    return {
        isLoading,
        table,
        globalFilter: useMemo(() => {
            return getGlobalFilterProps(table);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [globalFilter]),
        pagination: useMemo(() => {
            return getPaginationProps(table, currentData?.data.pagination, "Banners");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [paginationData, currentData?.data.pagination]),
    };
};
