import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import EyeOutlined from "@ant-design/icons/lib/icons/EyeOutlined";
import { Dropdown, MenuProps } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";

import { useModalOpener } from "../../../../../../hooks/helpers/useModalOpener";
import { IDeleteOrderBody, IOrder } from "../../../../../../types/orders";
import EditOrderModal from "../../../../../modals/Orders/EditOrderModal";
import OrderInfoModal from "../../../../../modals/Orders/OrderInfoModal";

const Actions = ({
    value,
    deleteOrder,
}: {
	value: IOrder;
    deleteOrder: (_data: IDeleteOrderBody) => void;
}) => {
    const {
        isOpened: showOrderModalIsOpened,
        open: openShowOrderModal,
        close: closeShowOrderModal,
    } = useModalOpener();

    const {
        isOpened: editOrderModalIsOpened,
        open: openEditOrderModal,
        close: closeEditOrderModal,
    } = useModalOpener();

    const onClick: MenuProps["onClick"] = async ({ key }) => {
        if (key === "delete") {
            deleteOrder({
		        id: value.id,
	        });
        } else if (key === "show") {
            openShowOrderModal();
        } else if (key === "edit") {
            openEditOrderModal();
        }
    };

    const items: MenuProps["items"] = [{
        key: "show",
        icon: <EyeOutlined className={"grey-7-color"}/>,
        label: <span
            data-testid={"show-order"}
            className={"grey-7-color"}
        >
		    Show Info
        </span>,
    }, {
        key: "edit",
        icon: <EditOutlined className={"grey-7-color"}/>,
        label: <span
            data-testid={"edit-order"}
            className={"grey-7-color"}
        >
		    Edit Order
        </span>,
    }, {
        key: "delete",
	    icon: <DeleteOutlined className={"red-color"}/>,
	    label: <span
		    data-testid={"delete-order"}
		    className={"red-color"}
	    >
		    Delete Order
        </span>,
    }];

    return <div>
	    {!!items.length && <Dropdown
		    menu={{ items, onClick }}
		    placement="bottomRight"
		    trigger={["click"]}
	    >
            <BsThreeDotsVertical
		        style={{ marginTop: 6 }}
		        className={"fs22 pointer grey-7-color"}
            />
	    </Dropdown>}

        {showOrderModalIsOpened && <OrderInfoModal
            close={closeShowOrderModal}
            orderId={value.id}
        />}

        {editOrderModalIsOpened && <EditOrderModal
            close={closeEditOrderModal}
            orderId={value.id}
        />}
    </div>;
};

export default Actions;
