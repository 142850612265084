import React from "react";

import { IOrder } from "../../../../../../types/orders";

interface CellProps extends React.HTMLAttributes<HTMLDivElement> {
    value: IOrder;
    isAlignedLeft?: boolean;
}

const Name: React.FC<CellProps> = ({
    value,
    isAlignedLeft = false,
    ...props
}) => {
    return <div {...props} className={isAlignedLeft ? "" : "horizontal-center"}>
        {value.source.info.name} ({value.info.itemType})
    </div>;
};

export default Name;
