import { useEffect, useMemo, useState } from "react";

import { ColumnDef, getCoreRowModel, getFilteredRowModel, PaginationState, useReactTable } from "@tanstack/react-table";

import { PAGE_SIZES } from "../../constants/pagination";
import { useDeleteMaintenanceRequestMutation, useGetMaintenanceRequestsQuery } from "../../services/maintenance-requests";
import { IDeleteMaintenanceRequestBody, IMaintenanceRequest } from "../../types/maintenance-requests";
import { getGlobalFilterProps, getPaginationProps } from "../../utils/table";

export const useMaintenanceRequestsTable = (getColumns: ({
    // eslint-disable-next-line no-unused-vars
    deleteMaintenanceRequest,
}: {
    deleteMaintenanceRequest: (_data: IDeleteMaintenanceRequestBody) => void
}) => ColumnDef<IMaintenanceRequest>[]) => {
    const [globalFilter, setGlobalFilter] = useState<string>("");

    const [{
        pageIndex,
        pageSize,
    }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: PAGE_SIZES[0],
    });

    const paginationData = useMemo(
        () => ({ pageIndex, pageSize }),
        [pageIndex, pageSize]
    );

    const {
        currentData,
        isLoading: isLoadingGetMaintenanceRequests,
        isFetching: isFetchingGetMaintenanceRequests,
    } = useGetMaintenanceRequestsQuery({
        pageIndex,
        pageSize,
        globalFilter,
    });

    const [deleteMaintenanceRequest, {
        isLoading: isLoadingDeleteMaintenanceRequest,
    }] = useDeleteMaintenanceRequestMutation();

    useEffect(() => {
        setPagination(prevState => ({
            ...prevState,
            pageIndex: 0,
        }));
    }, [
        globalFilter,
        pageSize,
    ]);

    const table = useReactTable(
        {
            columns: useMemo(() => getColumns({
                deleteMaintenanceRequest,
            }), [deleteMaintenanceRequest, getColumns]),
            data: useMemo(() => currentData?.data.maintenanceRequests || [], [currentData?.data.maintenanceRequests]),
            pageCount: currentData?.data.pagination.totalPages,
            state: {
                pagination: paginationData,
                globalFilter,
            },
            onPaginationChange: setPagination,
            onGlobalFilterChange: setGlobalFilter,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            manualPagination: true,
            manualFiltering: true,
        }
    );

    const isLoading = useMemo(() => {
        return isLoadingGetMaintenanceRequests || isFetchingGetMaintenanceRequests || isLoadingDeleteMaintenanceRequest;
    }, [
        isLoadingGetMaintenanceRequests,
        isFetchingGetMaintenanceRequests,
        isLoadingDeleteMaintenanceRequest,
    ]);

    return {
        isLoading,
        table,
        globalFilter: useMemo(() => {
            return getGlobalFilterProps(table);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [globalFilter]),
        pagination: useMemo(() => {
            return getPaginationProps(table, currentData?.data.pagination, "Maintenance Requests");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [paginationData, currentData?.data.pagination]),
    };
};
