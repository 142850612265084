import { useCallback } from "react";

import { Button, Form, message, Modal } from "antd";

import ServiceForm from "./components/ServiceForm";
import { useGetServiceQuery, useUpdateServiceMutation } from "../../../services/services";
import { IServiceForm } from "../../../types/services";
import Spinner from "../../basic/Spinner/Spinner";

const EditServiceModal = ({
    close,
    serviceId,
}: {
    close: () => void;
    serviceId: string;
}) => {
    const [form] = Form.useForm();

    const [updateService, {
        isLoading: isLoadingUpdateService,
    }] = useUpdateServiceMutation();

    const {
        data,
        isLoading: isLoadingGetService,
    } = useGetServiceQuery({
        id: serviceId,
    });

    const onFinish = useCallback(async (values: IServiceForm) => {
        if (!values.image) {
            return message.error("Upload image!");
        }

        await updateService({
            id: serviceId,
            data: {
                info: {
                    name: values.name,
                    description: values.description,
                },
                kind: values.kind,
                image: values.image,
                // prices: Object.entries(values.prices).flatMap(([key, value]) => {
                //     const [frequency, kind] = key.split("-");
                //     return {
                //         kind: kind as SubscriptionKind,
                //         frequency: frequency as FrequencyType,
                //         value,
                //     };
                // }),
            },
        });
        close();
    }, [close, serviceId, updateService]);

    return <Modal
        open
        width={900}
        title={
            <div className={"p20 modal-header grey-7-color"}>
				Edit Service
            </div>
        }
        afterClose={() => form.resetFields()}
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
					Cancel
                </Button>

                <Button
                    htmlType="submit"
                    onClick={() => form.submit()}
                    type={"primary"}
                    className={"primary-button"}
                >
					Save
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <Spinner isLoading={isLoadingGetService || isLoadingUpdateService}>
                {data && <ServiceForm
                    form={form}
                    initialValues={{
                        name: data.data.service.info.name,
                        description: data.data.service.info.description,
                        kind: data.data.service.kind,
                        prices: data.data.service.prices.reduce((acc, item) => {
                            acc[`${item.frequency}^${item.kind}`] = item.value;
                            return acc;
                        }, {} as {
                            [_key: string]: number;
                        }),
                    }}
                    onFinish={onFinish}
                    isEdit={true}
                />}
            </Spinner>
        </div>
    </Modal>;
};

export default EditServiceModal;
