import { api } from "./connection/api";
import {
    ICreateBannerBody,
    ICreateBannerResponse, IDeleteBannerBody, IDeleteBannerResponse,
    IGetBannerBody,
    IGetBannerResponse,
    IGetBannersQuery,
    IGetBannersResponse, IUpdateBannerBody, IUpdateBannerResponse,
} from "../types/banners";
import { getBannersQueryString } from "../utils/queries";

const ENDPOINT_PREFIX = "banners";

export const bannersApi = api.injectEndpoints({
    endpoints: build => ({
        getBanners: build.query<IGetBannersResponse, IGetBannersQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getBannersQueryString(query)}`,
            }),
            providesTags: ["Banners"],
        }),
        getBanner: build.query<IGetBannerResponse, IGetBannerBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "GET",
            }),
            providesTags: ["Banners"],
        }),
        createBanner: build.mutation<ICreateBannerResponse, ICreateBannerBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["Banners"],
        }),
        updateBanner: build.mutation<IUpdateBannerResponse, IUpdateBannerBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: ["Banners", "Banner"],
        }),
        deleteBanner: build.mutation<IDeleteBannerResponse, IDeleteBannerBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Banners"],
        }),
    }),

});

export const {
    useGetBannersQuery,
    useGetBannerQuery,
    useCreateBannerMutation,
    useUpdateBannerMutation,
    useDeleteBannerMutation,
} = bannersApi;
