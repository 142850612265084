import { api } from "./connection/api";
import {
    ICreateMaintenanceRequestBody,
    ICreateMaintenanceRequestResponse,
    IDeleteMaintenanceRequestBody,
    IDeleteMaintenanceRequestResponse,
    IGetMaintenanceRequestBody,
    IGetMaintenanceRequestResponse,
    IGetMaintenanceRequestsQuery,
    IGetMaintenanceRequestsResponse,
    IUpdateMaintenanceRequestBody,
    IUpdateMaintenanceRequestResponse,
} from "../types/maintenance-requests";
import { getMaintenanceRequestsQueryString } from "../utils/queries";

const ENDPOINT_PREFIX = "maintenance-requests";

export const maintenanceRequestsApi = api.injectEndpoints({
    endpoints: build => ({
        getMaintenanceRequests: build.query<IGetMaintenanceRequestsResponse, IGetMaintenanceRequestsQuery>({
            query: query => ({
                url: `${ENDPOINT_PREFIX}?${getMaintenanceRequestsQueryString(query)}`,
            }),
            providesTags: (result, _error, _arg) =>
                (result
                    ? [...result.data.maintenanceRequests.map(({ id }) => ({ type: "MaintenanceRequests" as const, id })), "MaintenanceRequests"]
                    : ["MaintenanceRequests"]),
        }),
        getMaintenanceRequest: build.query<IGetMaintenanceRequestResponse, IGetMaintenanceRequestBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "GET",
            }),
            providesTags: ["MaintenanceRequest"],
        }),
        createMaintenanceRequest: build.mutation<ICreateMaintenanceRequestResponse, ICreateMaintenanceRequestBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/`,
                method: "POST",
                body: data.data,
            }),
            invalidatesTags: ["MaintenanceRequests"],
        }),
        updateMaintenanceRequest: build.mutation<IUpdateMaintenanceRequestResponse, IUpdateMaintenanceRequestBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: ["MaintenanceRequests", "MaintenanceRequest"],
        }),
        deleteMaintenanceRequest: build.mutation<IDeleteMaintenanceRequestResponse, IDeleteMaintenanceRequestBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["MaintenanceRequests"],
        }),
    }),

});

export const {
    useGetMaintenanceRequestsQuery,
    useGetMaintenanceRequestQuery,
    useCreateMaintenanceRequestMutation,
    useUpdateMaintenanceRequestMutation,
    useDeleteMaintenanceRequestMutation,
} = maintenanceRequestsApi;
