import React from "react";

import ResidentsHeader from "./components/ResidentsHeader";
import { getColumns } from "./components/ResidentsTable/columns";
import ResidentsTable from "./components/ResidentsTable/ResidentsTable";
import { useUsersTable } from "../../../hooks/users/useUsersTable";
import PageHeader from "../../basic/PageHeader/PageHeader";
import Spinner from "../../basic/Spinner/Spinner";
import Pagination from "../../common/Table/components/Pagination";

const Residents = () => {
    const {
        isLoading,
        table,
        pagination,
        globalFilter,
    } = useUsersTable(getColumns, {
        role: "resident",
    });

    return <div>
        <div>
            <PageHeader menu={[]}/>
        </div>

        <div className={"common-page scroll-block"}>
            <div className={"common-block"}>
                <Spinner isLoading={isLoading}>
                    <div>
                        <ResidentsHeader
                            globalFilter={globalFilter}
                        />
                    </div>

                    <div className={"mt10"}>
                        <ResidentsTable
                            table={table}
                        />
                    </div>

                    <div className={"mt10"}>
                        <Pagination
                            pagination={pagination}
                        />
                    </div>
                </Spinner>
            </div>
        </div>
    </div>;
};

export default Residents;
