import { useCallback } from "react";

import { Button, Form, Modal } from "antd";

import MaintenanceRequestForm from "./components/MaintenanceRequestForm";
import {
    useGetMaintenanceRequestQuery,
    useUpdateMaintenanceRequestMutation,
} from "../../../services/maintenance-requests";
import { IMaintenanceRequestForm } from "../../../types/maintenance-requests";
import Spinner from "../../basic/Spinner/Spinner";

const EditMaintenanceRequestModal = ({
    close,
    maintenanceRequestId,
}: {
    close: () => void;
    maintenanceRequestId: string;
}) => {
    const [form] = Form.useForm();

    const [updateMaintenanceRequest, {
        isLoading: isLoadingUpdateMaintenanceRequest,
    }] = useUpdateMaintenanceRequestMutation();

    const {
        data,
        isLoading: isLoadingGetMaintenanceRequest,
    } = useGetMaintenanceRequestQuery({
        id: maintenanceRequestId,
    });

    const onFinish = useCallback(async (values: IMaintenanceRequestForm) => {
        const completedAtValue = values.completed ? new Date() : null;
        await updateMaintenanceRequest({
            id: maintenanceRequestId,
            data: {
                info: {
                    presentness: values.presentness,
                    urgency: values.urgency,
                    description: values.description,
                },
                completedAt: completedAtValue,
            },
        });

        close();
    }, [close, maintenanceRequestId, updateMaintenanceRequest]);

    return <Modal
        open
        width={900}
        title={
            <div className={"p20 modal-header grey-7-color"}>
				Edit Maintenance request
            </div>
        }
        afterClose={() => form.resetFields()}
        destroyOnClose
        onCancel={() => close()}
        footer={
            <div
                style={{ height: 40 }} className={"space-between"}>
                <Button
                    type={"link"}
                    onClick={() => close()}
                    className={"default-button"}
                >
					Cancel
                </Button>

                <Button
                    htmlType="submit"
                    onClick={() => form.submit()}
                    type={"primary"}
                    className={"primary-button"}
                >
					Save
                </Button>
            </div>
        }
    >
        <div className={"p20"}>
            <Spinner isLoading={isLoadingGetMaintenanceRequest || isLoadingUpdateMaintenanceRequest}>
                {data && <MaintenanceRequestForm
                    form={form}
                    initialValues={{
                        presentness: data.data.maintenanceRequest.info.presentness,
                        urgency: data.data.maintenanceRequest.info.urgency,
                        description: data.data.maintenanceRequest.info.description,
                        completed: !!data.data.maintenanceRequest.completedAt,
                    }}
                    onFinish={onFinish}
                />}
            </Spinner>
        </div>
    </Modal>;
};

export default EditMaintenanceRequestModal;
