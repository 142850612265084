import { useEffect, useMemo, useState } from "react";

import { ColumnDef, getCoreRowModel, getFilteredRowModel, PaginationState, useReactTable } from "@tanstack/react-table";

import { PAGE_SIZES } from "../../constants/pagination";
import { SOCKETS_EVENTS } from "../../constants/sockets";
import { useDeleteOrderMutation, useGetOrdersQuery } from "../../services/orders";
import { IDeleteOrderBody, IOrder, StatusType } from "../../types/orders";
import { getGlobalFilterProps, getPaginationProps } from "../../utils/table";
import useSocketNotifications from "../auth/useSocketNotifications";

export const useOrdersTable = (getColumns: ({
    // eslint-disable-next-line no-unused-vars
    deleteOrder,
}: {
    deleteOrder: (_data: IDeleteOrderBody) => void
}) => ColumnDef<IOrder>[]) => {
    const [globalFilter, setGlobalFilter] = useState<string>("");
    const [status, setStatus] = useState<StatusType>("new");
    const [selectedUserId, setSelectedUserId] = useState<string | undefined>(undefined);
    const [selectedDateRange, setSelectedDateRange] = useState<[string, string]>(["", ""]);

    const [{
        pageIndex,
        pageSize,
    }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: PAGE_SIZES[0],
    });

    const paginationData = useMemo(
        () => ({ pageIndex, pageSize }),
        [pageIndex, pageSize]
    );

    const {
        currentData,
        isLoading: isLoadingGetOrders,
        isFetching: isFetchingGetOrders,
        refetch: refreshOrders,
    } = useGetOrdersQuery({
        pageIndex,
        pageSize,
        globalFilter,
        status,
        user: selectedUserId,
        dateRange: selectedDateRange,
    });

    const [deleteOrder, {
        isLoading: isLoadingDeleteOrder,
    }] = useDeleteOrderMutation();

    useEffect(() => {
        setPagination(prevState => ({
            ...prevState,
            pageIndex: 0,
        }));
    }, [
        globalFilter,
        pageSize,
    ]);

    const table = useReactTable(
        {
            columns: useMemo(() => getColumns({
                deleteOrder,
            }), [deleteOrder, getColumns]),
            data: useMemo(() => currentData?.data.orders || [], [currentData?.data.orders]),
            pageCount: currentData?.data.pagination.totalPages,
            state: {
                pagination: paginationData,
                globalFilter,
            },
            onPaginationChange: setPagination,
            onGlobalFilterChange: setGlobalFilter,
            getCoreRowModel: getCoreRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            manualPagination: true,
            manualFiltering: true,
        }
    );

    const isLoading = useMemo(() => {
        return isLoadingGetOrders || isFetchingGetOrders || isLoadingDeleteOrder;
    }, [
        isLoadingGetOrders,
        isFetchingGetOrders,
        isLoadingDeleteOrder,
    ]);

    useSocketNotifications(SOCKETS_EVENTS.notifications, refreshOrders);

    return {
        isLoading,
        table,
        status,
        setStatus,
        selectedUserId,
        setSelectedUserId,
        setSelectedDateRange,
        refreshOrders,
        selectedDateRange,
        globalFilter: useMemo(() => {
            return getGlobalFilterProps(table);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [globalFilter]),
        pagination: useMemo(() => {
            return getPaginationProps(table, currentData?.data.pagination, "Orders");
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [paginationData, currentData?.data.pagination]),
    };
};
