import { useState } from "react";

import { CloseCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useDebouncedCallback } from "use-debounce";

import { useModalOpener } from "../../../../hooks/helpers/useModalOpener";
import { IGlobalFilterProps } from "../../../../types/common";
import CreateServiceModal from "../../../modals/Services/CreateServiceModal";

const ServicesHeader = ({
    globalFilter,
}: {
    globalFilter: IGlobalFilterProps
}) => {
    const {
        open: openCreateServiceModal,
        isOpened: createServiceModalIsOpened,
        close: closeCreateServiceModal,
    } = useModalOpener();

    const [globalFilterValue, setGlobalFilterValue] = useState(globalFilter.globalFilter);

    const handleSearchChange = useDebouncedCallback((value: string) => {
        return globalFilter.setGlobalFilter(value);
    }, 500);

    return <div className={"p10 space-between"}>
        <div>
            <div className={"fs16 bold"}>
                Services and Luxe
            </div>
        </div>
        <div className={"horizontal-right"}>
            <div>
                <Input
                    value={globalFilterValue}
                    size="large"
                    placeholder="Search"
                    allowClear={{
                        clearIcon: <div
                            onClick={() => {
                                setGlobalFilterValue("");
                                globalFilter.setGlobalFilter("");
                            }}
                        >
                            <CloseCircleOutlined />
                        </div>,
                    }}
                    onChange={e => {
                        setGlobalFilterValue(e.target.value);
                        handleSearchChange(e.target.value);
                    }}
                    prefix={<SearchOutlined />}
                />
            </div>

            <div className={"ml20"}>
                <Button
                    type={"primary"}
                    className={"primary-button"}
                    style={{ width: 140, height: 36 }}
                    icon={<PlusOutlined/>}
                    onClick={openCreateServiceModal}
                >
                    Add service
                </Button>
            </div>
        </div>

        {createServiceModalIsOpened && <CreateServiceModal
            close={closeCreateServiceModal}
        />}
    </div>;
};

export default ServicesHeader;
