import { useCallback, useState } from "react";

import { Col, Form, FormInstance, Input, Row } from "antd";

import { SERVICE_KINDS } from "../../../../constants/services";
import { FREQUENCIES, SUBSCRIPTION_KINDS } from "../../../../constants/subscriptions";
import { useUploadFileMutation } from "../../../../services/files";
import { IFile } from "../../../../types/files";
import { IServiceForm } from "../../../../types/services";
import FileUpload from "../../../common/Files/FileUpload";
import CommonSelect from "../../../inputs/Form/Inputs/CommomSelect";
import CommonTextArea from "../../../inputs/Form/Inputs/CommonTextArea";
import NameInput from "../../../inputs/Form/Inputs/NameInput";

const ServiceForm = ({
    form,
    initialValues,
    onFinish,
    isEdit = false,
}: {
    form: FormInstance;
    initialValues: IServiceForm;
    onFinish: (_values: IServiceForm) => void;
    isEdit: boolean;
}) => {
    const [imageData, setImageData] = useState<IFile | undefined>();

    const [uploadFileAsync] = useUploadFileMutation();

    const uploadFile = useCallback(async (file: File) => {
        const uploadedFileResponse = await uploadFileAsync({
            file: file,
            path: "services/images",
        }).unwrap();

        if (!uploadedFileResponse?.data?.url) return "";

        return uploadedFileResponse.data.url;
    }, [uploadFileAsync]);

    return <Form
        form={form}
        layout={"vertical"}
        initialValues={initialValues}
        onFinish={values => onFinish({
            ...values,
            image: imageData,
        })}
    >
        <Row gutter={[32, 32]}>
            <Col span={14}>
                <div>
                    <NameInput
                        name={"name"}
                        label={"Name"}
                        required
                    />
                </div>

                <div>
                    <CommonSelect
                        label={"Service kind"}
                        name={"kind"}
                        options={SERVICE_KINDS}
                    />
                </div>

                <div>
                    <CommonTextArea
                        required
                        rows={4}
                        label={"Description"}
                        name={"description"}
                        placeholder={"Input description..."}
                    />
                </div>
            </Col>

            <Col span={10}>
                <div>
                    {!imageData ? <FileUpload
                        height={324}
                        name={"service-image"}
                        uploadFile={uploadFile}
                        file={imageData}
                        setFile={setImageData}
                    /> : <div>
                        <div>
                            <img
                                src={imageData.url}
                                width={"100%"}
                                alt={"service"}
                            />
                        </div>

                        <div className={"mt10 horizontal-right"}>
                            <div
                                className={"red-color pointer"}
                                onClick={() => {
                                    setImageData(undefined);
                                }}
                            >
                                Remove
                            </div>
                        </div>
                    </div>}
                </div>
            </Col>
        </Row>

        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Row gutter={[32, 16]}>
                    {SUBSCRIPTION_KINDS.map(kind => {
                        return <Col
                            key={kind.id}
                            span={24 / SUBSCRIPTION_KINDS.length}
                        >
                            <div className={"mb20 bold"}>
                                {kind.name} price
                            </div>

                            {FREQUENCIES.map(item => {
                                return <div
                                    key={`${item.frequency}-${kind.id}`}
                                >
                                    <Form.Item
                                        label={`${item.name}`}
                                        name={["prices", `${item.frequency}^${kind.id}`]}
                                        rules={[{
                                            required: true,
                                            message: "Missing price",
                                        }]}
                                    >
                                        <Input
                                            disabled={isEdit}
                                            type={"number"}
                                            placeholder="Price"
                                        />
                                    </Form.Item>
                                </div>;
                            })}
                        </Col>;
                    })}
                </Row>
            </Col>
        </Row>
    </Form>;
};

export default ServiceForm;
