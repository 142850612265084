import React, { useState } from "react";

import NewPushNotification from "./components/NewPushNotification";
import PushNotificationsHeader from "./components/PushNotificationsHeader";
import PageHeader from "../../basic/PageHeader/PageHeader";
import Spinner from "../../basic/Spinner/Spinner";

const MaintenanceRequests = () => {
    const [isSending, setIsSending] = useState(false);
    return <div>
        <div>
            <PageHeader menu={[]}/>
        </div>

        <div className={"common-page scroll-block"}>
            <div className={"common-block"}>
                <Spinner isLoading={isSending}>
                    <div>
                        <PushNotificationsHeader/>
                    </div>
                    <div className={"mt10"}>
                        <NewPushNotification setIsSending={setIsSending}/>
                    </div>
                </Spinner>
            </div>
        </div>
    </div>;
};

export default MaintenanceRequests;
