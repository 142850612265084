export const FREQUENCIES = [{
    frequency: "weekly",
    name: "Weekly",
}, {
    frequency: "bi-weekly",
    name: "BiWeekly",
}, {
    frequency: "monthly",
    name: "Monthly",
}, {
    frequency: "quarterly",
    name: "Quarterly",
}] as const;

export const FREQUENCY_KEYS = FREQUENCIES.map(item => item.frequency);

export const TIMEFRAMES = [{
    id: "morning",
    name: "7:00-11:59 AM",
}, {
    id: "afternoon",
    name: "12:00-4:59 PM",
}] as const;

export const TIMEFRAME_KEYS = TIMEFRAMES.map(item => item.id);

export const STATUSES = [{
    id: "waiting",
    name: "Waiting",
}, {
    id: "in-progress",
    name: "In progress",
}, {
    id: "completed",
    name: "Completed",
}, {
    id: "cancelled",
    name: "Cancelled",
}] as const;

export const STATUS_KEYS = STATUSES.map(item => item.id);

export const SUBSCRIPTION_KINDS = [{
    id: "monthly",
    name: "Monthly",
}, {
    id: "yearly",
    name: "Annual",
}] as const;

export const SUBSCRIPTION_KIND_KEYS = SUBSCRIPTION_KINDS.map(item => item.id);
